import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import { GET_BUYERS } from '../graphql/buyer'
import { GET_CARTS } from '../graphql/cart'
import ConfirmationDialog from '../common/confirmationDialog'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import PrintIcon from '@material-ui/icons/Print'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AutocompleteField from '../common/autocompleteField'
import Loading from '../common/loading'
import HealthreportUploadDialog from './healthreportUploadDialog'
import CartDialog from './membercartDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
  },
  {
    id: 'Qty',
    label: 'Quantity',
  },
  {
    id: 'UnitPrice',
    currency: true,
    label: 'Unit Price',
  },
  {
    id: 'InvoiceAmount',
    currency: true,
    label: 'Total Amount',
  },
  {
    id: 'SalesPerson',
    label: 'Sales Person',
  },
  /*
  {
    id: 'UnitPrice',
    label: 'UnitPrice',
  },
  {
    id: 'UnitsInStock',
    label: 'UnitsInStock',
  },
  {
    id: 'UnitsInOrder',
    label: 'UnitsInOrder',
  }, */
]

export default function MemberCart() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openCart, setOpenCart] = useState(false)
  const [openHeader, setOpenHeader] = useState(false)
  const [confirm, setConfirm] = useState(false)

  const [customer, setCustomer] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [cart, setCart] = useState()

  const {
    loading: loadingBuyers,
    data: { buyers } = { buyers: [] },
  } = useQuery(GET_BUYERS)

  const { data: { carts } = { carts: [] }, refetch } = useQuery(GET_CARTS)

  const handleClickOpen = () => {
    /* setCart()
    setSelected([])
    setOpenHeader(true) */
    setCart()
    setSelected([])
    setOpenCart(true)
  }

  const handlePrint = () => {
    setOpen(true)
  }

  /* 
  const handleClickActive = () => {
    setActive({
      variables: {
        id: selected[0],
        CustomerCode: customer,
      },
    })
  } */

  /* const tableButtons = [
    {
      name: 'Active',
      icon: EmojiObjectsIcon,
      method: handleClickActive,
    },
    {
      name: 'Set Prices & Print cart',
      icon: AttachMoneyIcon,
      method: handlePrint,
    },
  ] */

  //if (error) return <p>API ERROR</p>

  return (
    <div className={classes.root}>
      {/*  <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Quote?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <CartDialog
        key={+new Date() + Math.random()}
        data={cart}
        setCart={setCart}
        setSelected={setSelected}
        customer={customer}
        open={openCart}
        setOpen={setOpenCart}
      />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          {loadingBuyers && <Loading />}
          {!loadingBuyers && (
            <AutocompleteField
              name="buyer"
              label="Customer"
              value={customerName}
              options={buyers.map(
                (item) => `${item.Phone} — ${item.FirstName} ${item.LastName}`
              )}
              onChange={(e, value) => {
                const buyerValue = buyers.find(
                  (item) =>
                    `${item.Phone} — ${item.FirstName} ${item.LastName}` ===
                    value
                )
                if (buyerValue) {
                  setCustomer(buyerValue.UserID)
                  setCustomerName(
                    `${buyerValue.Phone} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                  )

                  refetch({ UserID: buyerValue.UserID })
                }
              }}
            />
          )}
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          {customer && (
            <React.Fragment>
              <Button
                variant="contained"
                style={{ marginTop: 30 }}
                classes={{ root: classes.newButton }}
                color="primary"
                onClick={handleClickOpen}
              >
                New
              </Button>

              {/*  <pre>{JSON.stringify(carts, 0, 4)}</pre> */}
              <TableHalf
                clickOnSelect={true}
                hideSearch={true}
                hideDelete={true}
                hideChange={true}
                setConfirm={setConfirm}
                selected={selected}
                setSelected={setSelected}
                tableState={setCart}
                tableData={carts}
                setOpen={setOpenCart}
                tableHead={tableHead}
              ></TableHalf>
            </React.Fragment>
          )}

          {/*  {!loading && customer && carts.length === 0 && (
            <p>No cart found.</p>
          )}
          {!loading && customer && carts.length > 0 && (
            <TableHalf
              tableButtons={tableButtons}
              setConfirm={setConfirm}
              selected={selected}
              setSelected={setSelected}
              tableState={setCart}
              tableData={carts}
              setOpen={setOpenHeader}
              tableHead={tableHead}
            ></TableHalf>
          )} */}
        </Grid>
      </Grid>
    </div>
  )
}
