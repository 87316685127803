import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import OfficestaffDialog from './officestaffDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import { GET_BOOKINGS, RECEIVED_BOOKING } from '../graphql/booking'
import BookingDetailsDialog from './bookingDetailsDialog'
import Booking from './booking'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'BookFrom',
    label: 'Booking Date',
    width: 150,
  },
  {
    id: 'BookingNo',
    label: 'Booking No',
    width: 120,
  },
  {
    id: 'FullName',
    label: 'Client',
    width: 320,
  },
  {
    id: 'TotalPerson',
    label: 'Total Person',
    numeric: true,
    width: 120,
  },
  {
    id: 'Status',
    label: 'Status',
    statuscolor: true,
    width: 150,
  } /* ,
  {
    id: 'ReceivedBy',
    label: 'Customer Service',
  }  */,
  {
    id: 'ReceivedBy',
    logdate: 'ReceivedOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Customer Service',
  },
]

export default function Officestaff() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openBooking, setOpenBooking] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [booking, setBooking] = useState()

  const {
    loading,
    data: { bookings } = { bookings: [] },
    refetch: refetchBooking,
  } = useQuery(GET_BOOKINGS)

  const handleClickOpen = () => {
    setSelected([])
    setOpenBooking(true)
  }

  return (
    <div className={classes.root}>
      {/*  <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button> */}
      <Booking
        key={+new Date() + Math.random()}
        setOpen={setOpenBooking}
        open={openBooking}
        refetchBooking={refetchBooking}
      />
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        style={{ marginLeft: 5 }}
        classes={{ root: classes.newButton }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchBooking()
        }}
      >
        Refresh
      </Button>

      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <BookingDetailsDialog
            setOpen={setOpen}
            setSelected={setSelected}
            data={booking}
            open={open}
            refetchBooking={refetchBooking}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            rowsPerPage={10}
            clickOnSelect={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setBooking}
            tableData={bookings}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
