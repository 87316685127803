import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'
import { SEARCH_AWB } from './components/graphql/awb'
import { SEARCH_CONS } from './components/graphql/consignment'
import { useQuery, useMutation } from '@apollo/react-hooks'

const useStyles = makeStyles(theme => ({
  root2: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto'
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 180,
      '&:focus': {
        width: 180
      }
    }
  }
}))

const options = ['CN', 'HAWB', 'Invoice']

export default function AppSearch(props) {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const anchorRef = useRef(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleClickListItem = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const {
    data: { searchconsignment } = { searchconsignment: [] },
    refetch
  } = useQuery(SEARCH_CONS)
  const {
    data: { searchawb } = { searchawb: [] },
    refetch: refetchAwb
  } = useQuery(SEARCH_AWB)

  const [searchText, setSearchText] = useState('')

  const handleToggle = event => {
    if (event.key !== 'Enter') return
    if (!event.target.value) return

    const value = event.target.value

    setRedirect(true)
  }

  useEffect(() => {
    setRedirect(false)
  }, [redirect])

  return (
    <React.Fragment>
      {selectedIndex === 0 && redirect && (
        <Redirect to={`/inquiry/${searchText}`} />
      )}
      {selectedIndex === 1 && redirect && (
        <Redirect to={`/inquiryawb/${searchText}`} />
      )}

      {selectedIndex === 2 && redirect && (
        <Redirect to={`/inquiryinvoice/${searchText}`} />
      )}
      <List style={{ padding: 0 }} component="nav" aria-label="Device settings">
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          onClick={handleClickListItem}
        >
          <ListItemIcon
            style={{ paddingRight: 0, marginRight: 0, minWidth: 30 }}
          >
            <ExpandMoreIcon
              style={{ color: 'white', paddingRight: 0, marginRight: 0 }}
            />
          </ListItemIcon>
          <ListItemText primary={options[selectedIndex]} />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={event => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          value={searchText}
          placeholder="Search"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          onChange={event => {
            if (event.target.value)
              setSearchText(event.target.value.replace(/[^\w\s]/gi, ''))
          }}
          onKeyDown={handleToggle}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
    </React.Fragment>
  )
}
