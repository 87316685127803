import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'

import {
  INSERT_TRANSACTION,
  UPDATE_TRANSACTION,
  REMOVE_TRANSACTION,
  TRANSACTION_HISTORY,
} from '../graphql/transaction'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import { useSnackbar } from 'notistack'

const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function TransactionDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [reason, setReason] = useState()

  const updateCache = (cache, { data }) => {
    if (data.updatetransaction.Error) {
      enqueueSnackbar(data.updatetransaction.Error, {
        variant: 'error',
      })

      return
    }

    const old = cache.readQuery({
      query: TRANSACTION_HISTORY,
      variables: {
        FromDate: props.from,
        ToDate: props.to,
      },
    })

    const latest = data.updatetransaction

    const foundIndex = old.transactionhistory.findIndex(
      (item) => item.id === latest.id
    )
    old.transactionhistory.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: TRANSACTION_HISTORY,
      variables: {
        FromDate: props.from,
        ToDate: props.to,
      },
      data: { transactionhistory: [...old.transactionhistory] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setTransaction()
    props.handleClickSearch()
  }

  const [updateTransaction] = useMutation(UPDATE_TRANSACTION, {
    update: updateCache,
  })

  const handleClickConfirm = () => {
    if (!reason) {
      setConfirm(false)
      enqueueSnackbar('Please fill up the Reason field', {
        variant: 'error',
      })

      return
    }

    updateTransaction({
      variables: {
        id: props.data && props.data.id,
        RefundReason: reason,
      },
    })
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickConfirm}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure?"
        okButton="Yes"
        title="Continue"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Refund
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <TextField
                name="Qty"
                label="Reason to refund"
                margin="dense"
                fullWidth
                multiline
                defaultValue={reason}
                onChange={(e) => {
                  setReason(e.target.value)
                }}
                autoComplete="off"
                type="number"
              />
              <br />
              <br />{' '}
              <Button
                variant="contained"
                disableElevation
                color="primary"
                onClick={() => {
                  setConfirm(true)
                }}
              >
                Refund item
              </Button>
              <br />
              <br />
              <br />
            </DialogContent>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
