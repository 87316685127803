import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { parseISO } from 'date-fns'

import { Link, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { useMutation, useQuery } from '@apollo/react-hooks'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import TableHalf from '../common/tableHalf'
import { GET_MYPASS } from '../graphql/order'
import { GET_BUYERS } from '../graphql/buyer'
import {
  INSERT_BOOKING,
  GET_BOOKINGS,
  REMOVE_BOOKING,
  BOOKING_SCHEDULE,
  GET_BOOKINGORDERS,
} from '../graphql/booking'
import Cookies from 'js-cookie'
import Moment from 'moment-timezone'

import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import QRCode from 'qrcode.react'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import SingleTitle from '../common/singleTitle'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import CheckIcon from '@material-ui/icons/Check'
import Fade from '@material-ui/core/Fade'
import TextFieldDisplay from '../common/textFieldDisplay'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import AutocompleteField from '../common/autocompleteField'
import { useSnackbar } from 'notistack'
import DatepickerField from '../common/datepickerField'

import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import LoginDialog from './loginDialog'

const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
  },
  root: {
    padding: 0,
  },
  chiproot: {
    display: 'flex',
    justifyContent: 'left',
    paddingLeft: 20,
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  menuitem: {
    textAlign: 'left',
    display: 'block',
  },
  avatar: {
    color: 'white',
    backgroundColor: '#00acee',
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'Title',
    label: 'Package',
  },
  {
    id: 'ServiceExpiryDate',
    label: 'Expires at',
    date: true,
  },
  {
    id: 'ServiceTimes',
    label: 'Total Pass',
  },
  {
    id: 'TotalUsed',
    label: 'Used Pass',
  },
]

export default function Booking(props) {
  const classes = useStyles()
  const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [showForm, setShowForm] = useState(true)
  const [showActive, setShowActive] = useState(false)
  const [branch, setBranch] = useState()
  const [time, setTime] = useState()
  const [totalHours, setTotalHours] = useState()
  const [booking, setBooking] = useState()
  const [confirm, setConfirm] = useState(false)
  const [totalPerson, setTotalPerson] = useState()

  const [checked, setChecked] = useState(true)
  const [customer, setCustomer] = useState('200')
  const [customerName, setCustomerName] = useState('')
  const [packagePriceID, setPackagePriceID] = useState()
  const [totalPersonSelect, setTotalPersonSelect] = useState([])

  const { loading: loadingBuyers, data: { buyers } = { buyers: [] } } =
    useQuery(GET_BUYERS)

  const {
    loading: loadingMypass,
    refetch: refetchMypass,
    data: { mypass } = { mypass: [] },
  } = useQuery(GET_MYPASS, {
    variables: {
      CustomerID: '200',
    },
  })

  const {
    loading: loadingSchedule,
    refetch: refetchBookingSchedules,
    data: { bookingschedules } = { bookingschedules: [] },
  } = useQuery(BOOKING_SCHEDULE)

  const [value, setValue] = useState(0)

  const [staff, setStaff] = useState('')
  const [staffName, setStaffName] = useState('')
  const [staffID, setStaffID] = useState()
  const [openLogin, setOpenLogin] = useState(false)
  const [selected, setSelected] = useState([])

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  const {
    refetch: refetchBookingOrders,
    data: { bookingorders } = { bookingorders: [] },
  } = useQuery(GET_BOOKINGORDERS, {
    variables: { id: customer },
    skip: !customer,
  })

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const addCache = (cache, { data }) => {
    if (data.insertbooking.Error) {
      enqueueSnackbar(data.insertbooking.Error, {
        variant: 'error',
      })
    }
    props.refetchBooking()
    refetchBookingSchedules({
      BookingDate: Moment(new Date()).tz('Asia/Kuala_Lumpur'),
    })
  }

  const [insertBooking] = useMutation(INSERT_BOOKING, {
    update: addCache,
  })

  const removeCache = (cache, { data }) => {
    if (data.cancelbooking.Error) {
      enqueueSnackbar(data.cancelbooking.Error, {
        variant: 'error',
      })
    }
    props.refetchBooking()
    refetchBookingSchedules({
      BookingDate: Moment(new Date()).tz('Asia/Kuala_Lumpur'),
    })
  }

  const [removeBooking] = useMutation(REMOVE_BOOKING, {
    update: removeCache,
  })

  const handleDateChange = (value) => {
    setSelectedDate(Moment(value).tz('Asia/Kuala_Lumpur'))
    refetchBookingSchedules({
      BookingDate: Moment(value).tz('Asia/Kuala_Lumpur'),
    })
  }

  useEffect(() => {
    refetchBookingSchedules({
      BookingDate: Moment(new Date()).tz('Asia/Kuala_Lumpur'),
    })

    refetchMypass({
      CustomerID: '200',
    })
  }, [props])

  /* useEffect(() => {
    setBooking()
    setShowForm(true)
    if (bookings.find((a) => !a.ReceivedBy)) {
      setShowForm(false)
      setBooking(bookings.find((a) => !a.ReceivedBy))
    }
  }, [bookings]) */

  const handleSubmit = (password) => {
    if (password && customer && selectedDate && time && totalPerson) {
      insertBooking({
        variables: {
          CustomerID: customer,
          BookDate: selectedDate,
          BookTime: time,
          TotalPerson: totalPerson,
          /* TotalHours: parseFloat(totalHours), */
          TotalHours: 1,
          SalesPerson: staff,
          Password: password,
          PackagePriceID: packagePriceID,
        },
      })

      props.setOpen(false)
      props.setOpenLogin(false)
    } else {
      enqueueSnackbar('Please fill in the form', {
        variant: 'error',
      })
    }
  }

  const handleClickDelete = () => {
    removeBooking({
      variables: {
        id: booking.id,
      },
    })
    setConfirm(false)
  }

  return (
    <React.Fragment>
      <LoginDialog
        staffID={staffID}
        setOpen={setOpenLogin}
        open={openLogin}
        action={handleSubmit}
        title={`Password for ${staff}`}
        dialogTitle={`Password`}
      />
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to cancel Booking?"
        okButton="Yes"
        title=""
      />
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            New Booking
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <Container maxWidth="md">
              <div className={classes.root}>
                {/* <pre>here{JSON.stringify(bookingschedules, 0, 4)}</pre> */}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {showForm && (
                    <div>
                      <Grid container spacing={3}>
                        <Grid item md={12} xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked}
                                onClick={() => {
                                  setChecked(!checked)
                                  setCustomer('200')
                                  refetchMypass({
                                    CustomerID: '200',
                                  })
                                  setCustomerName('') /* 
                                  refetchBookingSchedules({
                                    BookingDate: Moment(new Date()).tz(
                                      'Asia/Kuala_Lumpur'
                                    ),
                                  }) */
                                }}
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                              />
                            }
                            label="Walk In Customer"
                          />
                          <br />
                          {!loadingBuyers && !checked && (
                            <Fade in={!loadingBuyers && !checked}>
                              <AutocompleteField
                                name="buyer"
                                label="Customer"
                                value={customerName}
                                options={buyers
                                  .filter((a) => a.UserID != 200)
                                  .map(
                                    (item) =>
                                      `${item.Phone} — ${item.FirstName} ${item.LastName}`
                                  )}
                                onChange={(e, value) => {
                                  const buyerValue = buyers.find(
                                    (item) =>
                                      `${item.Phone} — ${item.FirstName} ${item.LastName}` ===
                                      value
                                  )
                                  if (buyerValue) {
                                    refetchMypass({
                                      CustomerID: buyerValue.UserID,
                                    })

                                    setCustomer(buyerValue.UserID)
                                    setCustomerName(
                                      `${buyerValue.Phone} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                                    )
                                  }

                                  /* refetchBookingSchedules({
                                    BookingDate: Moment(new Date()).tz(
                                      'Asia/Kuala_Lumpur'
                                    ),
                                  }) */
                                }}
                              />
                            </Fade>
                          )}
                          <br />
                          <Button
                            variant="contained"
                            disableElevation
                            style={{ marginBottom: 10 }}
                            color="primary"
                            onClick={() => {
                              refetchMypass({ CustomerID: customer })
                            }}
                          >
                            Refresh Package
                          </Button>
                          {loadingMypass ? (
                            <Loading />
                          ) : (
                            <TableHalf
                              hideSearch={true}
                              disableSelected={true}
                              rowsPerPage={5}
                              clickOnSelect={false}
                              selected={selected}
                              setSelected={setSelected}
                              tableData={mypass}
                              tableHead={tableHead}
                            ></TableHalf>
                          )}
                        </Grid>
                      </Grid>

                      <Divider className={classes.dividerroot} />
                      <Grid container style={{ marginTop: 10 }}>
                        <Grid item md={5}>
                          <DatepickerField
                            disablePast={true}
                            name="BookingDate"
                            value={selectedDate ? selectedDate : null}
                            label="Booking Date"
                            format={'MMM, DD YYYY'}
                            onChange={handleDateChange}
                            autoComplete="off"
                          />

                          {/* <TextField
                            id="date"
                            label="Booking Date"
                            type="date"
                            className={classes.textField}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={handleDateChange}
                          /> */}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid style={{ marginTop: 15 }} item md={5}>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink id="localtion-label">
                              Booking Time
                            </InputLabel>
                            <Select
                              fullWidth
                              labelId="localtion-label"
                              value={time}
                              onChange={(e, value) => {
                                setTime(e.target.value)
                              }}
                            >
                              {bookingschedules.map((a, i) => (
                                <MenuItem
                                  key={i}
                                  classes={{ root: classes.menuitem }}
                                  value={a.value}
                                >
                                  {a.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container style={{ marginTop: 15 }}>
                        <Grid item md={6} xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink htmlFor="PackagePriceID">
                              Select Your Package
                            </InputLabel>
                            <Select
                              labelId="PackagePriceID"
                              id="PackagePriceID"
                              name="PackagePriceID"
                              value={packagePriceID}
                              onChange={(e) => {
                                setPackagePriceID(e.target.value)

                                const orders = bookingorders.find(
                                  (a) => a.value == e.target.value
                                )

                                if (orders && orders.MinBooking == 2) {
                                  setTotalPersonSelect([2])
                                } else {
                                  setTotalPersonSelect([1, 2])
                                }
                              }}
                            >
                              {bookingorders.map((a, i) => (
                                <MenuItem key={i} value={a.value}>
                                  {a.title}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText></FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {totalPersonSelect && totalPersonSelect.length > 0 && (
                        <Grid container style={{ marginTop: 15 }}>
                          <Grid item md={6}>
                            <FormControl className={classes.formControl}>
                              <InputLabel shrink id="localtion-label">
                                Total Person
                              </InputLabel>
                              <Select
                                fullWidth
                                labelId="localtion-label"
                                value={totalPerson}
                                onChange={(e, value) => {
                                  setTotalPerson(e.target.value)
                                }}
                              >
                                {totalPersonSelect.map((person, i) => (
                                  <MenuItem
                                    id={i}
                                    classes={{ root: classes.menuitem }}
                                    value={person}
                                  >
                                    {person}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>
                                Requires Booking Pass
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* <Fade in={branch}>
                    <Grid container style={{ marginTop: 25 }}>
                      <Grid item xs={5}>
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink id="totalhours-label">
                            Duration
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="totalhours-label"
                            value={totalHours}
                            onChange={(e, value) => {
                              setTotalHours(e.target.value)
                            }}
                          >
                            <MenuItem value="1">1 hour</MenuItem>
                            <MenuItem value="1.5">1.5 hours</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Fade> */}
                      <div style={{ marginTop: 50 }}>
                        <AutocompleteField
                          name="buyer"
                          label="Select Staff"
                          value={staffName}
                          options={officestaffs.map((item) =>
                            item.FirstName && item.LastName
                              ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                              : item.Username
                          )}
                          onChange={(e, value) => {
                            const buyerValue = officestaffs.find(
                              (item) =>
                                (item.FirstName && item.LastName
                                  ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                                  : item.Username) === value
                            )
                            if (buyerValue) {
                              setStaffID(buyerValue.UserID)
                              setStaff(buyerValue.Username)
                              setStaffName(
                                buyerValue.FirstName && buyerValue.LastName
                                  ? `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                                  : buyerValue.Username
                              )
                            }
                          }}
                        />
                        <br />
                      </div>

                      <div style={{ marginTop: 20, marginBottom: 15 }}>
                        <Button
                          disabled={mypass.ServiceTimes <= 0 || !staff}
                          variant="contained"
                          disableElevation
                          color="primary"
                          onClick={() => {
                            setOpenLogin(true)
                          }}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  )}
                </MuiPickersUtilsProvider>

                {/* {!showForm && (
              <Grid style={{ marginTop: 0 }} container spacing={10}>
                <Grid item sm={12} md={5}>
                  <div style={{ marginBottom: 20 }}>
                    <i
                      class="fad fa-check-circle"
                      style={{ color: '#00acee' }}
                    ></i>{' '}
                    One(1) Booking Pass has been deducted. <br />
                    <br />
                    Please present this code to our Customer Service counter to
                    Check In during your booking time.
                  </div>
                  {!booking.ReceivedBy && (
                    <div
                      style={{
                        backgroundColor: 'white',
                        padding: 80,
                      }}
                    >
                      <QRCode
                        style={{
                          backgroundColor: 'white',
                          width: '100%',
                        }}
                        value={`http://picturesofpeoplescanningqrcodes.tumblr.com/${booking.Token}`}
                        bgColor={'#ffffff'}
                        size={300}
                        fgColor={'#000000'}
                        level={'L'}
                        includeMargin={false}
                        renderAs={'svg'}
                        imageSettings={{
                          src: '/images/logo2.png',
                          x: null,
                          y: null,
                          height: 38,
                          width: 38,
                          excavate: true,
                        }}
                      />
                    </div>
                  )}

                  <br />
                  <br />
                  <Button
                    size="small"
                    variant="outlined"
                    disableElevation
                    color="primary"
                    onClick={() => {
                      //history.push('/booking/1')
                      setConfirm(true)
                    }}
                  >
                    Cancel Booking
                  </Button>
                </Grid>
                <Grid item sm={12} md={7}>
                  <Grid container spacing={3}>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      <TextFieldDisplay
                        label="Branch"
                        value={booking.BranchName}
                      />
                      <TextFieldDisplay
                        label="Booking Date & Time"
                        value={Moment(booking.BookFrom)
                          .tz('UTC')
                          .format('L - LT')}
                      />
                      <TextFieldDisplay
                        label="Duration"
                        value={`${booking.TotalHours} h`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )} */}
              </div>
            </Container>{' '}
          </DialogContent>
          {/* <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions> */}
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
