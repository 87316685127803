import React from 'react'
import { TextField } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles({
  root: {
    marginBottom: 8,
  },
})

const CssTextField = withStyles({
  root: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottomColor: 'transparent',
      borderBottom: 0,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'transparent',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: 'transparent',
    },
  },
})(TextField)

const TextFieldDisplay = (props) => {
  const classes = useStyles()

  return (
    <CssTextField
      inputProps={{
        readOnly: true,
      }}
      placeholder=""
      InputLabelProps={{
        shrink: true,
      }}
      margin="normal"
      {...props}
      fullWidth
      multiline
    />
  )
}

{
  /* <FormControl style={{ marginBottom: 20 }}>
    <InputLabel>{props.label}</InputLabel>
    <Input {...props} readOnly />
  </FormControl> */
}

/* <TextField
    {...props}
    placeholder=""
    fullWidth
    margin="dense"
    InputLabelProps={{
      shrink: true
    }}
  /> */
export default TextFieldDisplay
