import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Cookies from 'js-cookie'
import TabTitle from '../common/tabTItle'
import Product from './product'
import Stock from './stock'
import Transaction from './transaction'
import TransactionRefund from './transactionRefund'

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > div': {
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
  container: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(0),
  },
  padding: {
    padding: theme.spacing(3),
  },
  border: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={1}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export default function TransactionMain(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  function tabProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const access = {
    view1: [
      'Finance',
      'Spa Consultant',
      'Branch Manager',
      'Admin',
      'Management',
    ],
  }

  return (
    <React.Fragment>
      <CssBaseline />
      {access.view1.find((access) => access == Cookies.get('Department')) && (
        <Container className={classes.container}>
          <StyledTabs
            className={classes.border}
            variant="scrollable"
            scrollButtons="auto"
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab label="Sales transactions" {...tabProps(0)} />
            <StyledTab label="Refunded transactions" {...tabProps(1)} />
            {/*  <StyledTab label="Ship Advice" {...tabProps(1)} />
                
                <StyledTab label="Upload POD" {...tabProps(2)} /> */}
          </StyledTabs>
          <TabPanel value={value} index={0}>
            <Transaction />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TransactionRefund />
          </TabPanel>
        </Container>
      )}
    </React.Fragment>
  )
}
