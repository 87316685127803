import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import InboxIcon from '@material-ui/icons/Inbox'
import DraftsIcon from '@material-ui/icons/Drafts'
import ReportDialog from './reportDialog'
import { Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: 'transparent',
  },
}))

export default function SelectedListItem(props) {
  const classes = useStyles()
  const [selectedIndex, setSelectedIndex] = useState()
  const [report, setReport] = useState()
  const [open, setOpen] = useState()

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    setReport(props.data[index])
    setOpen(true)
  }

  const colorChange = (text) => {
    if (text.trim() == 'Increase') {
      return '#3565a5'
    } else if (text.trim() == 'Mildly Abnormal(+)') {
      return '#3565a5'
    } else if (text.trim() == 'Moderately Abnormal(++)') {
      return '#d4b004'
    } else if (text.trim() == 'Severely Abnormal(+++)') {
      return '#fe0007'
    }

    return 'black'
  }

  return (
    <React.Fragment>
      {/* 
      <Hidden only={['sm', 'xs']}> */}
      {report && (
        <ReportDialog
          fullScreen={false}
          open={open}
          setOpen={setOpen}
          data={report}
        />
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          {props.data.map((a, i) => {
            if (i < Math.round(props.data.length / 2))
              return (
                <div style={{ marginTop: 40 }}>
                  <h3>
                    {a.Title}{' '}
                    <Button
                      variant="contained"
                      style={{ padding: 0 }}
                      disableElevation
                      color="primary"
                      size="small"
                      margin="dense"
                      onClick={(event) => handleListItemClick(event, i)}
                    >
                      More
                    </Button>
                  </h3>
                  {/* <Grid
                      id={i}
                      style={{ fontWeight: 'normal', fontSize: 11 }}
                      container
                      spacing={2}
                    >
                      <Grid item xs={2} md={5}>
                        Testing Item
                      </Grid>
                      <Grid item xs={2} md={5}>
                        Result
                      </Grid>
                    </Grid> */}

                  {a.HealthReportDetails.map(
                    (b, j) =>
                      b.TestingResult.trim() != 'Normal(-)' &&
                      b.TestingResult.trim() != 'Health scope' && (
                        <Grid
                          id={j}
                          style={{ fontWeight: 'normal' }}
                          container
                          spacing={2}
                        >
                          <Grid item xs={7} md={7}>
                            {b.TestingItem}
                          </Grid>
                          <Grid item xs={5} md={5}>
                            <span
                              style={{
                                fontWeight: 'bold',
                                color: colorChange(b.TestingResult),
                              }}
                            >
                              {b.TestingResult}
                            </span>
                          </Grid>
                        </Grid>
                      )
                  )}
                </div>
              )
          })}
        </Grid>

        <Grid item xs={12} md={6}>
          <List
            style={{ paddingTop: 0 }}
            component="nav"
            aria-label="secondary mailbox folder"
          >
            {props.data.map((a, i) => {
              if (i >= Math.round(props.data.length / 2))
                return (
                  <div style={{ marginTop: 40 }}>
                    <h3>
                      {a.Title}{' '}
                      <Button
                        variant="contained"
                        style={{ padding: 0 }}
                        disableElevation
                        color="primary"
                        size="small"
                        margin="dense"
                        onClick={(event) => handleListItemClick(event, i)}
                      >
                        More
                      </Button>
                    </h3>
                    {/* <Grid
                      id={i}
                      style={{ fontWeight: 'normal', fontSize: 11 }}
                      container
                      spacing={2}
                    >
                      <Grid item xs={2} md={5}>
                        Testing Item
                      </Grid>
                      <Grid item xs={2} md={5}>
                        Result
                      </Grid>
                    </Grid> */}

                    {a.HealthReportDetails.map(
                      (b, j) =>
                        b.TestingResult.trim() != 'Normal(-)' &&
                        b.TestingResult.trim() != 'Health scope' && (
                          <Grid
                            id={j}
                            style={{ fontWeight: 'normal' }}
                            container
                            spacing={2}
                          >
                            <Grid item xs={7} md={7}>
                              {b.TestingItem}
                            </Grid>
                            <Grid item xs={5} md={5}>
                              <span
                                style={{
                                  fontWeight: 'bold',
                                  color: colorChange(b.TestingResult),
                                }}
                              >
                                {b.TestingResult}
                              </span>
                            </Grid>
                          </Grid>
                        )
                    )}
                  </div>
                )
            })}
          </List>
        </Grid>
      </Grid>
      {/*  </Hidden>
      <Hidden only={['md', 'lg', 'xl']}>
        <div className={classes.root}>
          <ReportDialog
            fullScreen={true}
            open={open}
            setOpen={setOpen}
            data={report}
          />
          <List
            style={{ paddingTop: 0 }}
            component="nav"
            aria-label="secondary mailbox folder"
          >
            {props.data.map((a, i) => (
              <ListItem
                button
                selected={selectedIndex === i}
                onClick={(event) => handleListItemClick(event, i)}
              >
                <ListItemText primary={a.Title} />
                <ListItemSecondaryAction>good</ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      </Hidden> */}
    </React.Fragment>
  )
}
