import { gql } from 'apollo-boost'

export const GET_MYPASS = gql`
  query MyPass($CustomerID: ID) {
    mypass(CustomerID: $CustomerID) {
      id
      Title
      ServiceTimes
      PriceID
      ServiceExpiryDate
      TotalUsed
    }
  }
`

export const GET_ORDERS = gql`
  {
    orders {
      id
      OrderNo
      CustomerCode
      CustomerName
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DateLapse
      FirstName
      LastName
      Phone
      Email
      Address1
      Address2
      State
      City
      PostCode
      DeliveryCharges
      TotalAmount
      FinalTotal
      PaymentMethod
      PaymentRemark
      Received
      ReceivedBy
      ReceivedOn
      Client
      Token
      FullName
      Error
      Refund
      RefundReason
      RefundBy
      RefundOn
    }
  }
`

export const GET_ORDER = gql`
  query Order($OrderID: ID, $Token: ID) {
    order(OrderID: $OrderID, Token: $Token) {
      id
      OrderNo
      CustomerCode
      CustomerName
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DateLapse
      FirstName
      LastName
      Phone
      Email
      Address1
      Address2
      State
      City
      PostCode
      DeliveryCharges
      TotalAmount
      FinalTotal
      PaymentMethod
      PaymentRemark
      Received
      ReceivedBy
      ReceivedOn
      Client
      Error
      Refund
      RefundReason
      RefundBy
      RefundOn
    }
  }
`

export const GET_ORDERDETAILS = gql`
  query OrderDetails($OrderID: ID, $Token: ID) {
    orderdetails(OrderID: $OrderID, Token: $Token) {
      id
      OrderID
      OrderNo
      UserID
      ProductID
      InvoiceAmount
      Qty
      CreatedDate
      PriceID
      UnitPrice
      ProductName
      ProductName2
      Category
      ProductImage
      Refund
      RefundReason
      RefundBy
      RefundOn
    }
  }
`

export const GET_ORDER_STATUS = gql`
  query OrderStatus($OrderID: ID!) {
    orderstatus(OrderID: $OrderID) {
      id
      OrderID
      Status
      CreatedBy
      CreatedOn
    }
  }
`

export const GET_MYORDERS = gql`
  {
    myorders {
      id
      OrderNo
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      StatusCode
      TotalItem
      TotalAmount
      DeliveryCharges
      PaymentMethod
      Received
      ReceivedBy
      ReceivedOn
    }
  }
`

export const GET_MYORDER = gql`
  query MyOrder($OrderNo: String!) {
    myorder(OrderNo: $OrderNo) {
      id
      OrderNo
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      StatusCode
      TotalItem
      TotalAmount
      PaymentMethod
      Received
      ReceivedBy
      ReceivedOn
      DeliveryCharges
      OrderDetails {
        id
        OrderID
        OrderNo
        UserID
        ProductID
        InvoiceAmount
        Qty
        CreatedDate
        PriceID
        UnitPrice
        ProductName
        ProductName2
        Category
        ProductImage
      }
    }
  }
`

export const CART_ORDER = gql`
  mutation CartOrder($PaymentMethod: String) {
    cartorder(PaymentMethod: $PaymentMethod)
  }
`

export const RECEIVED_ORDER = gql`
  mutation ReceivedOrder($Token: ID, $SalesPerson: String, $Password: String) {
    receivedorder(
      Token: $Token
      SalesPerson: $SalesPerson
      Password: $Password
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DateLapse
      FirstName
      LastName
      Phone
      Email
      Address1
      Address2
      State
      City
      PostCode
      DeliveryCharges
      TotalAmount
      FinalTotal
      PaymentMethod
      PaymentRemark
      Received
      ReceivedBy
      ReceivedOn
      Client
      Token
      FullName
      Error
      Refund
      RefundReason
      RefundBy
      RefundOn
    }
  }
`

export const GET_NOZONE_ORDERS = gql`
  {
    nozoneorders {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedOn
    }
  }
`

export const UPDATE_ZONE = gql`
  mutation UpdateZone(
    $id: ID!
    $ZoneID: String
    $Status: String
    $Remarks: String
    $CreatedBy: String
    $StatusText: String
  ) {
    updatezone(
      id: $id
      ZoneID: $ZoneID
      Status: $Status
      Remarks: $Remarks
      CreatedBy: $CreatedBy
      StatusText: $StatusText
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedOn
    }
  }
`

export const CREATE_ORDERSTAFF = gql`
  mutation CreateOrderStaff(
    $Customer: ID
    $Orders: [CreateOrderInput!]!
    $SalesPerson: String
    $Password: String
    $PaymentMode: String
    $PaymentID: ID
    $PaymentCCName: String
    $PaymentRemark: String
  ) {
    createorderstaff(
      Customer: $Customer
      Orders: $Orders
      SalesPerson: $SalesPerson
      Password: $Password
      PaymentMode: $PaymentMode
      PaymentID: $PaymentID
      PaymentCCName: $PaymentCCName
      PaymentRemark: $PaymentRemark
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
      DateLapse
      FirstName
      LastName
      Phone
      Email
      Address1
      Address2
      State
      City
      PostCode
      DeliveryCharges
      TotalAmount
      FinalTotal
      PaymentMethod
      PaymentRemark
      Received
      ReceivedBy
      ReceivedOn
      Client
      Token
      FullName
      Error
      Refund
      RefundReason
      RefundBy
      RefundOn
    }
  }
`

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $CustomerCode: String!
    $NoOfCarton: String
    $Remarks: String
    $User: String
  ) {
    createorder(
      CustomerCode: $CustomerCode
      NoOfCarton: $NoOfCarton
      Remarks: $Remarks
      User: $User
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation UpdateOrder(
    $id: ID!
    $CustomerCode: String!
    $NoOfCarton: String
    $Remarks: String
    $User: String
  ) {
    updateorder(
      id: $id
      CustomerCode: $CustomerCode
      NoOfCarton: $NoOfCarton
      Remarks: $Remarks
      User: $User
    ) {
      id
      OrderNo
      CustomerCode
      CustomerName
      Address1
      Address2
      ZoneID
      NoOfCarton
      Remarks
      Status
      CreatedBy
      CreatedOn
      ModifiedBy
      LastModified
    }
  }
`

export const REMOVE_ORDER = gql`
  mutation RemoveOrder($id: ID!) {
    removeorder(id: $id)
  }
`
