import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Moment from 'moment-timezone'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ORDERDETAILS, RECEIVED_ORDER, GET_ORDER } from '../graphql/order'
import {
  GET_orderdetails,
  RECEIVED_BOOKING,
  CANCEL_BOOKING,
  GET_BOOKINGS,
} from '../graphql/booking'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import AutocompleteField from '../common/autocompleteField'
import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import BookingCancelDialog from './orderCancelDialog'
import Cookies from 'js-cookie'
import TextFieldDisplay from '../common/textFieldDisplay'
import OrderDetailsItem from './orderDetailsItem'
import { useSnackbar } from 'notistack'
import LoginDialog from './loginDialog'

const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),

    paddingBottom: theme.spacing(2),
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  submitBtn: {
    marginTop: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
    },
  },
}))

// START
export default function OrderDetailsDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [token, setToken] = useState(props.data && props.data.Token)

  const {
    loading: loadingOrderDetails,
    data: { orderdetails } = { orderdetails: [] },
  } = useQuery(GET_ORDERDETAILS, {
    variables: { OrderID: props.data && props.data.id },
  })

  const {
    loading: loadingOrder,
    data: { order } = { order: {} },
    refetch,
  } = useQuery(GET_ORDER, {
    variables: { OrderID: props.data && props.data.id },
  })

  const [staff, setStaff] = useState('')

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  const updateCache = (cache, { data }) => {
    if (data.receivedorder && data.receivedorder.Error) {
      enqueueSnackbar(data.receivedorder && data.receivedorder.Error, {
        variant: 'error',
      })
    }

    props.refetchRemaining()
    props.refetchOrder()
  }

  const [receivedOrder] = useMutation(RECEIVED_ORDER, {
    update: updateCache,
  })

  const [staffName, setStaffName] = useState('')
  const [staffID, setStaffID] = useState()
  const [openLogin, setOpenLogin] = useState(false)

  const [customerName, setCustomerName] = useState()
  const [customer, setCustomer] = useState()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const [totalAmount, setTotalAmount] = useState(0)
  const [finalAmount, setFinalAmount] = useState(0)

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_BOOKINGS,
    })

    if (data.cancelbooking.Error) {
      enqueueSnackbar(data.cancelbooking.Error, {
        variant: 'error',
      })
    }

    if (!data.cancelbooking.Error) {
      const latest = old.bookings.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_BOOKINGS,
        data: { bookings: [...latest] },
      })
    }
  }

  const [cancelBooking] = useMutation(CANCEL_BOOKING, {
    update: deleteCache,
  })

  const onSubmit = (values) => {
    /* if (props.data && props.data.id) {
      updateOrder({
        variables: {
          id: props.data.id,
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } else {
      createOrder({
        variables: {
          Remarks: values.Remarks,
          User: Cookies.get('StaffID'),
        },
      })
    } */
  }

  const handleClickCollected = (password) => {
    receivedOrder({
      variables: {
        Token: token,
        SalesPerson: staff,
        Password: password,
      },
    })
    setConfirm(false)
    props.setOpen(false)
  }
  /* 
  const handleClickDelete = () => {
    cancelBooking({
      variables: {
        id: props.data.id,
      },
    })
    setConfirmDelete(false)
    props.setOpen(false)
  } */

  /*  useEffect(() => {
    if (orderdetails.length == 0) return

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails]) */

  /* useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount]) */

  useEffect(() => {
    if (orderdetails.length == 0) return

    console.log(orderdetails)

    const totalAmount = orderdetails.reduce((a, b) => a + b.InvoiceAmount, 0)
    setTotalAmount(totalAmount)
  }, [orderdetails])

  useEffect(() => {
    setFinalAmount(totalAmount)
  }, [totalAmount])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      scroll="body"
      open={props.open}
      onClose={() => {
        props.setOpen(false)
      }}
      disableBackdropClick={false}
      classes={{ paper: classes.dialogPaper }}
      aria-labelledby="manifesting-dialog"
    >
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirmDelete}
        setConfirm={setConfirmDelete}
        message="Continue remove Booking?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <React.Fragment>
        <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
          Order Details
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              props.setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div className={classes.root}>
            <ConfirmationDialog
              action={handleClickCollected}
              confirm={confirm}
              setConfirm={setConfirm}
              message="Confirm customer collected?"
              okButton="Yes"
              title="Customer collected"
            />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={0}>
                <Grid item md={7} xs={12}>
                  <Grid container spacing={0}>
                    <Grid item md={4} xs={6}>
                      <TextFieldDisplay
                        label="Order No"
                        value={props.data && props.data.OrderNo}
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <TextFieldDisplay
                        label="Order Total"
                        value={`RM ${parseFloat(totalAmount).toFixed(2)}`}
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <TextFieldDisplay
                        label="Order Date"
                        value={`${Moment(
                          props.data && props.data.CreatedOn
                        ).format('L')} ${Moment(
                          props.data && props.data.CreatedOn
                        ).format('LT')}`}
                      />
                    </Grid>
                    {/* 
          <Grid style={{ paddingTop: 0 }} item md={4} xs={4}>
            <TextFieldDisplay
              label="Order Total"
              value={`RM ${parseFloat(finalAmount).toFixed(2)}`}
            />
          </Grid> */}
                  </Grid>
                  <Divider classes={{ root: classes.dividerroot }} />
                  <Grid container spacing={0}>
                    <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
                      <TextFieldDisplay
                        label="Name"
                        value={`${props.data && props.data.FirstName} ${
                          props.data && props.data.LastName
                        }`}
                      />
                    </Grid>
                    <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
                      {props.data && props.data.Phone && (
                        <TextFieldDisplay
                          label="Phone"
                          value={(props.data && props.data.Phone) || '-'}
                        />
                      )}
                    </Grid>
                    <Grid style={{ paddingTop: 0 }} item md={4} xs={12}>
                      {props.data && props.data.Email && (
                        <TextFieldDisplay
                          label="Email"
                          value={(props.data && props.data.Email) || '-'}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={0}>
                    <Grid style={{ paddingTop: 0 }} item md={6} xs={12}>
                      {props.data && props.data.Address1 && (
                        <TextFieldDisplay
                          label="Address 1"
                          value={`${props.data && props.data.Address1}
${props.data && props.data.Address2}
${props.data && props.data.PostCode}, ${props.data && props.data.City}
${props.data && props.data.State}
                        `}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    paddingTop: 0,
                    background: '#ddd',
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 20,
                  }}
                  item
                  md={5}
                  xs={12}
                >
                  <h2 style={{ fontSize: 14 }}>Order Items</h2>
                  {/* <pre>{JSON.stringify(orderdetails, 0, 4)}</pre> */}
                  {orderdetails.map((a, i) => (
                    <OrderDetailsItem key={i} data={a} />
                  ))}
                  {/* {JSON.stringify(props.data, 0, 0, 4)} */}
                </Grid>
              </Grid>
            </form>
            {props.data &&
              !props.data.ReceivedBy &&
              orderdetails.filter((a) => a.Category == '1').length > 0 && (
                <React.Fragment>
                  <div style={{ marginTop: 0, maxWidth: 300 }}>
                    <AutocompleteField
                      name="buyer"
                      label="Select Staff"
                      value={staffName}
                      options={officestaffs.map((item) =>
                        item.FirstName && item.LastName
                          ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                          : item.Username
                      )}
                      onChange={(e, value) => {
                        const buyerValue = officestaffs.find(
                          (item) =>
                            (item.FirstName && item.LastName
                              ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                              : item.Username) === value
                        )
                        if (buyerValue) {
                          setStaffID(buyerValue.UserID)
                          setStaff(buyerValue.Username)
                          setStaffName(
                            buyerValue.FirstName && buyerValue.LastName
                              ? `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                              : buyerValue.Username
                          )
                        }
                      }}
                    />
                  </div>

                  {/* <Hidden only={['xs', 'sm']}>
                  <Divider classes={{ root: classes.dividerroot }} />
                </Hidden> */}
                  <Grid container spacing={0}>
                    {/* <Grid item md={3} xs={12}>
                    <AutocompleteField
                      name="buyer"
                      label="Assign Customer Service"
                      value={staffName}
                      options={officestaffs.map(
                        (item) =>
                          `${item.Username} — ${item.FirstName} ${item.LastName}`
                      )}
                      onChange={(e, value) => {
                        const buyerValue = officestaffs.find(
                          (item) =>
                            `${item.Username} — ${item.FirstName} ${item.LastName}` ===
                            value
                        )
                        if (buyerValue) {
                          setStaff(buyerValue.Username)
                          setStaffName(
                            `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                          )
                        }
                      }}
                    />
                  </Grid> */}
                    <Grid item md={3} xs={12}>
                      <LoginDialog
                        staffID={staffID}
                        setOpen={setOpenLogin}
                        open={openLogin}
                        action={handleClickCollected}
                        title={`Password for ${staff}`}
                        dialogTitle={`Password`}
                      />

                      <Button
                        disabled={!staff}
                        className={classes.submitBtn}
                        variant="contained"
                        disableElevation
                        color="primary"
                        onClick={() => {
                          setOpenLogin(true)
                        }}
                      >
                        Collected Order
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            {props.data &&
              props.data.ReceivedBy &&
              orderdetails.filter((a) => a.Category == '1').length > 0 && (
                <Grid container spacing={0}>
                  <Grid item md={12} xs={12}>
                    <br />
                    <Alert severity="success">
                      <AlertTitle>Success</AlertTitle>
                      Order collected. Customer Service assigned to{' '}
                      {props.data && props.data.ReceivedBy}
                      {'.'}
                    </Alert>
                  </Grid>
                </Grid>
              )}
          </div>
        </DialogContent>
      </React.Fragment>
    </Dialog>
  )
}
