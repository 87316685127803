import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import OfficestaffDialog from './officestaffDialog'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import { GET_ORDERS } from '../graphql/order'
import BookingDetailsDialog from './bookingDetailsDialog'
import OrderDetailsDialog from './orderDetailsDialog'
import { propTypes } from 'react-currency-format'
import OrderNewDialog from './orderNewDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'CreatedOn',
    label: 'Order Date',
    datetime: true,
    datetz: 'Asia/Kuala_Lumpur',
    dateformat: 'MM-DD-YYYY',
    timeformat: 'LT',
    width: 150,
  },
  {
    id: 'OrderNo',
    label: 'Order No',
    width: 120,
  },
  {
    id: 'FullName',
    label: 'Client',
    width: 320,
  },
  {
    id: 'TotalAmount',
    label: 'Order Total',
    numeric: true,
    currency: true,
    width: 120,
  },
  {
    id: 'Status',
    label: 'Status',
    statuscolor: true,
    width: 150,
  },
  /* {
    id: 'ReceivedBy',
    label: 'Customer Service',
  } */
  {
    id: 'ReceivedBy',
    logdate: 'ReceivedOn',
    dateLapse: 'DateLapse',
    log: true,
    disablePadding: true,
    label: 'Customer Service',
  } /* ,
  {
    id: 'BranchName',
    label: 'Outlet',
  } */,
]

export default function OrderSchedule(props) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openNew, setOpenNew] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [order, setOrder] = useState()

  const {
    loading,
    data: { orders } = { orders: [] },
    refetch: refetchOrder,
  } = useQuery(GET_ORDERS)

  const handleClickOpen = () => {
    setSelected([])
    setOpenNew(true)
  }

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        style={{ marginLeft: 5 }}
        classes={{ root: classes.newButton }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchOrder()
        }}
      >
        Refresh
      </Button>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <OrderDetailsDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            data={order}
            open={open}
            refetchOrder={refetchOrder}
            refetchRemaining={props.refetchRemaining}
          />
          <OrderNewDialog
            key={+new Date() + Math.random()}
            setOpen={setOpenNew}
            data={order}
            open={openNew}
            setOrder={setOrder}
            setSelected={setSelected}
            refetchOrder={refetchOrder}
            refetchRemaining={props.refetchRemaining}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            rowsPerPage={10}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setOrder}
            tableData={orders}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
