import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import PunchcardDialog from './punchcardDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { PUNCHCARD_HISTORY, GET_PUNCHCARD } from '../graphql/punchcard'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import RefreshIcon from '@material-ui/icons/Refresh'
import CropFreeIcon from '@material-ui/icons/CropFree'
import TimerIcon from '@material-ui/icons/Timer'
import QRCode from 'qrcode.react'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'PunchDate',
    datetime: true,
    label: 'Date',
  },
  {
    id: 'PunchType',
    label: 'Type',
  },
  {
    id: 'Username',
    label: 'Username',
  },
  {
    id: 'FirstName',
    label: 'First Name',
  },
  {
    id: 'LastName',
    label: 'Last Name',
  },
]

export default function Punchcard(props) {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [punchcardSelect, setPunchcardSelect] = useState()
  const [showQr, setShowQr] = useState(false)

  const {
    loading,
    data: { punchcard } = { punchcard: [] },
    refetch,
    startPolling,
    stopPolling,
  } = useQuery(GET_PUNCHCARD)

  const {
    data: { punchcardhistory } = { punchcardhistory: [] },
    refetch: refetchPunchcard,
    startPolling: startPollingHistory,
    stopPolling: stopPollingHistory,
  } = useQuery(PUNCHCARD_HISTORY)

  const [seconds, setSeconds] = useState(1)
  const [isActive, setIsActive] = useState(false)

  function toggle() {
    setIsActive(!isActive)
  }

  function reset() {
    setSeconds(0)
    setIsActive(false)
  }

  useEffect(() => {
    let interval = null
    if (isActive) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1)
      }, 1000)
    } else if (!isActive && seconds !== 0) {
      clearInterval(interval)
    }
    return () => clearInterval(interval)
  }, [isActive, seconds])

  useEffect(() => {
    refetch()
    refetchPunchcard()
  }, [props])

  useEffect(() => {
    if (showQr) {
      startPolling(1000)
      startPollingHistory(1000)
    } else {
      setTimeout(() => {
        stopPolling()
        stopPollingHistory()
      }, 2000)
    }
  }, [showQr])

  useEffect(() => {
    setShowQr(false)
    setSeconds(0)
    setIsActive(false)
  }, [punchcardhistory])

  return (
    <div className={classes.root}>
      {/* <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchPunchcard()
        }}
      >
        Refresh
      </Button> */}

      {/* <pre>{JSON.stringify(punchcard, 0, 4)}</pre> */}
      {!showQr && (
        <Button
          disableFocusRipple={true}
          autoFocus={true}
          size="large"
          variant="outlined"
          classes={{ root: classes.newButton }}
          color="primary"
          startIcon={<CropFreeIcon />}
          onClick={() => {
            setShowQr(true)
            setSeconds(1)
            setIsActive(true)
            const timer = setTimeout(() => {
              setShowQr(false)
              setSeconds(1)
              setIsActive(false)
            }, 20000)
            return () => clearTimeout(timer)
          }}
        >
          Punch IN/OUT
        </Button>
      )}

      {showQr && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0}
        >
          <Grid item style={{ textAlign: 'center' }} xs={12} sm={4}>
            <h3 style={{ color: '#4caf50' }}>Punch IN</h3>
            {punchcard.Token && (
              <QRCode
                value={`http://picturesofpeoplescanningqrcodes.tumblr.com/Checkin/${punchcard.Token}`}
                size={200}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'L'}
                includeMargin={false}
                renderAs={'svg'}
                imageSettings={{
                  src: '/images/logo2.png',
                  x: null,
                  y: null,
                  height: 38,
                  width: 38,
                  excavate: true,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TimerIcon />
              <p>{seconds}s</p>
            </div>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }} sm={4}>
            <h3 style={{ color: '#f44336' }}>Punch Out</h3>
            {punchcard.Token && (
              <QRCode
                value={`http://picturesofpeoplescanningqrcodes.tumblr.com/Checkout/${punchcard.Token}`}
                size={200}
                bgColor={'#ffffff'}
                fgColor={'#000000'}
                level={'L'}
                includeMargin={false}
                renderAs={'svg'}
                imageSettings={{
                  src: '/images/logo2.png',
                  x: null,
                  y: null,
                  height: 38,
                  width: 38,
                  excavate: true,
                }}
              />
            )}
          </Grid>
        </Grid>
      )}

      <Grid container style={{ marginTop: 30 }} spacing={0}>
        <Grid item xs={12} sm={12}>
          <Button
            size="small"
            variant="outlined"
            classes={{ root: classes.newButton }}
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              refetchPunchcard()
            }}
          >
            Refresh
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            clickOnSelect={true}
            hidePagination={false}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setPunchcardSelect}
            tableData={punchcardhistory}
            setOpen={setOpen}
            tableHead={tableHead}
            rowsPerPage={20}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
