import { gql } from 'apollo-boost'

export const GET_PRODUCT = gql`
  query Product($id: ID) {
    product(id: $id) {
      id
      ProductID
      DepartmentID
      Category
      CategoryName
      IDSKU
      ProductName
      Quantity
      UnitPrice
      Ranking
      ProductDesc
      UnitsInStock
      UnitsInOrder
      ProductImage
      ProductPrices
      PriceID
      Ordering
      PrettyUrl
      Youtube
    }
  }
`

export const GET_PRODUCTS = gql`
  query Products($offset: Int, $limit: Int) {
    products(offset: $offset, limit: $limit) {
      id
      ProductID
      DepartmentID
      Category
      CategoryName
      IDSKU
      ProductName
      Quantity
      UnitPrice
      Ranking
      ProductDesc
      UnitsInStock
      UnitsInOrder
      ProductImage
      ProductPrices
      PriceID
      Ordering
    }
  }
`

export const TOTAL_PRODUCTS = gql`
  query TOTAL_PRODUCTS {
    numberOfProducts {
      total
    }
  }
`

export const PRODUCT_IMAGES = gql`
  query ProductImages($id: ID!) {
    productimages(id: $id) {
      id
      Module
      ModuleID
      FileName
      FileType
      FileSize
      Ordering
    }
  }
`

export const GET_PRICELIST = gql`
  query PriceList($ProductID: ID) {
    pricelist(ProductID: $ProductID) {
      id
      ProductID
      Unit
      UnitPrice
      Uom
    }
  }
`

export const INSERT_PRICELIST = gql`
  mutation InsertPrice(
    $ProductID: ID
    $Unit: Int
    $UnitPrice: Float
    $Uom: String
  ) {
    insertprice(
      ProductID: $ProductID
      Unit: $Unit
      UnitPrice: $UnitPrice
      Uom: $Uom
    ) {
      id
      ProductID
      Unit
      UnitPrice
      Uom
    }
  }
`

export const UPDATE_PRICELIST = gql`
  mutation UpdatePrice($id: ID, $Unit: Int, $UnitPrice: Float, $Uom: String) {
    updateprice(id: $id, Unit: $Unit, UnitPrice: $UnitPrice, Uom: $Uom) {
      id
      ProductID
      Unit
      UnitPrice
      Uom
    }
  }
`

export const REMOVE_PRICELIST = gql`
  mutation RemovePrice($id: ID!) {
    removeprice(id: $id)
  }
`

export const REMOVE_PRODUCT = gql`
  mutation RemoveProduct($id: ID!) {
    removeproduct(id: $id)
  }
`

export const MAKE_IMAGEPRIMARY = gql`
  mutation MakePrimaryImage($id: ID, $ModuleID: ID) {
    makeprimaryimage(id: $id, ModuleID: $ModuleID)
  }
`

export const INSERT_PRODUCT = gql`
  mutation InsertProduct(
    $Category: String
    $IDSKU: String
    $ProductName: String
    $Quantity: String
    $Ordering: String
    $UnitPrice: Float
    $ProductDesc: String
    $UnitsInStock: Int
  ) {
    insertproduct(
      Category: $Category
      IDSKU: $IDSKU
      ProductName: $ProductName
      Quantity: $Quantity
      Ordering: $Ordering
      UnitPrice: $UnitPrice
      ProductDesc: $ProductDesc
      UnitsInStock: $UnitsInStock
    ) {
      id
      ProductID
      DepartmentID
      Category
      CategoryName
      IDSKU
      ProductName
      Quantity
      UnitPrice
      Ranking
      ProductDesc
      UnitsInStock
      UnitsInOrder
      ProductImage
      ProductPrices
      PriceID
      Ordering
    }
  }
`

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $id: ID
    $Category: String
    $IDSKU: String
    $ProductName: String
    $Quantity: String
    $Ordering: String
    $UnitPrice: Float
    $ProductDesc: String
    $UnitsInStock: Int
  ) {
    updateproduct(
      id: $id
      Category: $Category
      IDSKU: $IDSKU
      ProductName: $ProductName
      Quantity: $Quantity
      Ordering: $Ordering
      UnitPrice: $UnitPrice
      ProductDesc: $ProductDesc
      UnitsInStock: $UnitsInStock
    ) {
      id
      ProductID
      DepartmentID
      Category
      CategoryName
      IDSKU
      ProductName
      Quantity
      UnitPrice
      Ranking
      ProductDesc
      UnitsInStock
      UnitsInOrder
      ProductImage
      ProductPrices
      PriceID
      Ordering
    }
  }
`
