import React from 'react'
import Grid from '@material-ui/core/Grid'

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  h2: { paddingLeft: 20 },
  icon: { paddingLeft: 3 },
  subtitle: { fontSize: '1rem', lineHeight: 1 },
}))

export default function SingleTitle(props) {
  const classes = useStyles()

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <h2 className={classes.h2}>{props.title}</h2>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
