import { gql } from 'apollo-boost'

export const GET_CLOSINGHEADERS = gql`
  query ClosingHeaders($BranchCode: ID!, $ClosingType: String) {
    closingheaders(BranchCode: $BranchCode, ClosingType: $ClosingType) {
      id
      ClosingDate
      ClosingDateStart
      ClosingType
      StockOutQty
      StockInQty
      ClosingBalanceQty
      OpeningBalanceQty
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const GET_CLOSINGDETAILS = gql`
  query ClosingDetails($BranchCode: ID!, $ClosingID: ID!) {
    closingdetails(BranchCode: $BranchCode, ClosingID: $ClosingID) {
      id
      ClosingID
      ItemID
      ItemName
      StockOutQty
      StockInQty
      ClosingBalanceQty
      OpeningBalanceQty
      CreatedOn
      CreatedBy
    }
  }
`

export const INSERT_CLOSINGHEADER = gql`
  mutation InsertClosingHeader($ClosingDate: Date, $BranchCode: ID!) {
    insertclosingheader(ClosingDate: $ClosingDate, BranchCode: $BranchCode) {
      id
      ClosingDate
      ClosingDateStart
      ClosingType
      StockOutQty
      StockInQty
      ClosingBalanceQty
      OpeningBalanceQty
      CreatedOn
      CreatedBy
      UpdatedOn
      UpdatedBy
      Error
    }
  }
`

export const REMOVE_CLOSINGHEADER = gql`
  mutation RemoveClosingHeader($BranchCode: ID!, $id: ID!) {
    removeclosingheader(id: $id, BranchCode: $BranchCode) {
      id
      Error
    }
  }
`
