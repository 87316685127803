import { gql } from 'apollo-boost'

export const GET_CONS = gql`
  {
    consignments {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
      CreateBy
      CreateOn
      ModifiedBy
      LastModified
      ShipperName
      Express
    }
  }
`

export const GET_CON = gql`
  query Consignment($id: ID!) {
    consignment(id: $id) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
      CreateBy
      CreateOn
      ModifiedBy
      LastModified
      Express
    }
  }
`

export const SEARCH_CONS = gql`
  query SearchConsignment($consignment: String!) {
    searchconsignment(consignment: $consignment) {
      id
      ConsignmentNo
    }
  }
`

export const GET_CONS_BY_AWBID_PREMANIFEST = gql`
  query ConsignmentsByAwbIdPremanifest($id: ID!) {
    consignmentsByAwbIdPremanifest(id: $id) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
    }
  }
`

export const GET_CONS_BY_AWBID = gql`
  query ConsignmentsByAwbId($id: ID!) {
    consignmentsByAwbId(id: $id) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
    }
  }
`

export const GET_CONS_BLANK_AWBID = gql`
  query ConsignmentsBlankAwbId($state: String!) {
    consignmentsBlankAwbId(state: $state) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
    }
  }
`

export const GET_CONS_POD = gql`
  {
    podconsignments {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
    }
  }
`

export const UPDATE_CONSIGNMENT = gql`
  mutation UpdateConsignment(
    $id: ID!
    $ConsignmentNo: String
    $Weight: Float
    $Pieces: Int
    $ShipperCode: String
    $Destination: String
    $User: String
    $Express: String
  ) {
    updateConsignment(
      id: $id
      ConsignmentNo: $ConsignmentNo
      Weight: $Weight
      Pieces: $Pieces
      ShipperCode: $ShipperCode
      Destination: $Destination
      User: $User
      Express: $Express
    ) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
      CreateBy
      CreateOn
      ModifiedBy
      LastModified
      ShipperName
      Express
    }
  }
`

export const CREATE_CONSIGNMENT = gql`
  mutation CreateConsignment(
    $ConsignmentNo: String
    $Weight: Float
    $Pieces: Int
    $ShipperCode: String
    $Destination: String
    $User: String
    $Express: String
  ) {
    createConsignment(
      ConsignmentNo: $ConsignmentNo
      Weight: $Weight
      Pieces: $Pieces
      ShipperCode: $ShipperCode
      Destination: $Destination
      User: $User
      Express: $Express
    ) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
      ShipperCode
      CreateBy
      CreateOn
      ModifiedBy
      LastModified
      ShipperName
      Express
    }
  }
`

export const DELETE_CONS = gql`
  mutation DeleteConsignment($id: ID!) {
    deleteConsignment(id: $id)
  }
`

export const SET_LEFT = gql`
  mutation UpdateLeft($id: ID!, $AWBID: ID) {
    updateLeft(id: $id, AWBID: $AWBID) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
    }
  }
`

export const SET_RIGHT = gql`
  mutation UpdateRight($id: ID!, $AWBID: ID) {
    updateRight(id: $id, AWBID: $AWBID) {
      id
      ConsignmentNo
      Pieces
      Weight
      Destination
      AirlineID
      AWB
      AWBID
    }
  }
`
