import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useForm from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import TextFieldDisplay from '../common/textFieldDisplay'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { PUNCH_IN, GET_PUNCHCARD } from '../graphql/punchcard'
import Cookies from 'js-cookie'
import QrReader from 'react-qr-reader'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

// START Cancelled
export default function PunchcardScanDialog(props) {
  const classes = useStyles()
  const history = useHistory()
  const myRef = React.useRef()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()

  const { refetch } = useQuery(GET_PUNCHCARD)

  const updateCache = (cache, { data }) => {
    refetch()
    props.setOpen(false)
    //alert('done')
  }

  const [punchIn] = useMutation(PUNCH_IN, { update: updateCache })

  const onSubmit = (values) => {
    console.log(values)

    /* updateZone({
      variables: {
        id: props.data.id,
        ZoneID: props.data.ZoneID,
        Status: 'Cancelled',
        CreatedBy: Cookies.get('StaffID'),
        StatusText: values.Reason,
      },
    }) */
    props.setOpen(false)
  }

  const [scanresult, setScanresult] = useState()

  const handleScan = (data) => {
    if (data && typeof data == 'string') {
      setScanresult(data)

      const scansplit = data.split('/')

      punchIn({
        variables: {
          Token: scansplit[scansplit.length - 1],
          PunchType: scansplit[scansplit.length - 2],
        },
      })

      //alert('Scanned qrcode ' + data + ' ' + scansplit[scansplit.length - 1])
      alert('Scanned')
      //history.push(`/bookingdetails/${scansplit[scansplit.length - 1]}`)
    }
  }
  const handleError = (err) => {
    console.error(err)
    alert(err)
  }

  const openImageDialog = () => {
    myRef.current.openImageDialog()
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            Scan QR Code
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={0}>
                <Grid style={{ padding: 0 }} item md={12} xs={12}>
                  <QrReader
                    ref={myRef}
                    /* legacyMode */
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    /* onImageLoad={handleScan} */
                    style={{ width: '100%' }}
                  />
                  {/* <input
                    type="button"
                    value="Submit QR Code"
                    onClick={openImageDialog}
                  /> */}
                  <br />
                  <br />
                  <br />
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(scanresult, null, 4)}</pre> */}
            </DialogContent>
            {/* <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions> */}
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
