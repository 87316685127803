import React, { useState, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import MemberDialog from './memberDialog'
import { GET_ORDERS, REMOVE_ORDER } from '../graphql/order'
import { GET_MEMBERS } from '../graphql/member'
import ConfirmationDialog from '../common/confirmationDialog'
import Loading from '../common/loading'
import AutocompleteField from '../common/autocompleteField'
import RefreshIcon from '@material-ui/icons/Refresh'
import { GET_BRANCHES } from '../graphql/branch'
import MemberNewDialog from './memberNewDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'Username',
    label: 'Username',
  },
  {
    id: 'FirstName',
    label: 'First Name',
  },
  {
    id: 'LastName',
    label: 'Last Name',
  },
  {
    id: 'Email',
    label: 'Email',
  },
  {
    id: 'Phone',
    label: 'Phone',
  },
  {
    id: 'Client',
    label: 'Branch',
  },
  {
    id: 'CreatedOn',
    label: 'Member Since',
    date: true,
  },
  {
    id: 'ServiceTimes',
    label: 'Balance Pass',
  },
]

export default function Member() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [openNew, setOpenNew] = useState(false)
  const [open, setOpen] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [member, setMember] = useState()
  const [branch, setBranch] = useState()
  const [branchName, setBranchName] = useState()

  const {
    loading,
    data: { members } = { members: [] },
    refetch: refetchMember,
  } = useQuery(GET_MEMBERS)

  const { data: { branches } = { branches: [] }, refetch: refetchBranches } =
    useQuery(GET_BRANCHES)

  const handleClickOpen = () => {
    setMember()
    setSelected([])
    setOpenNew(true)
  }

  useEffect(() => {
    refetchMember({
      Branch: branch,
    })
  }, [branch])

  ////if (loading) return <Loading />

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        disableElevation
        classes={{ root: classes.newButton }}
        color="primary"
        onClick={handleClickOpen}
      >
        New
      </Button>
      <Button
        variant="outlined"
        classes={{ root: classes.newButton }}
        style={{ marginLeft: 5 }}
        color="primary"
        /* startIcon={<RefreshIcon />} */
        onClick={() => {
          refetchMember({ Branch: '' })
          setBranchName('')
          setBranch('')
        }}
      >
        View All
      </Button>
      <br />
      <Grid container spacing={0}>
        <Grid item xs={12} sm={3}>
          <AutocompleteField
            name="filterBranch"
            label="Filter by Branch"
            value={branchName}
            options={branches.map((item) => item.BranchName)}
            onChange={(e, value) => {
              const branchValue = branches.find(
                (item) => item.BranchName === value
              )
              if (branchValue) {
                refetchBranches({ Branch: branchValue.BranchCode })
                setBranch(branchValue.BranchCode)
                setBranchName(branchValue.BranchName)
              }
            }}
          />
          <br />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12}>
          <MemberNewDialog
            key={+new Date() + Math.random()}
            setOpen={setOpenNew}
            setSelected={setSelected}
            setMember={setMember}
            data={member}
            open={openNew}
            refetchMember={refetchMember}
          />

          <MemberDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setMember={setMember}
            data={member}
            open={open}
            refetchMember={refetchMember}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableHalf
            rowsPerPage={10}
            clickOnSelect={true}
            hideChange={true}
            hideDelete={true}
            setConfirm={setConfirm}
            selected={selected}
            setSelected={setSelected}
            tableState={setMember}
            tableData={members}
            setOpen={setOpen}
            tableHead={tableHead}
          ></TableHalf>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {order && order.id && (
            <div className={classes.displayDiv}>
              <ProductDisplay data={order} />
            </div>
          )}
        </Grid> */}
      </Grid>
      {/*  <pre>{JSON.stringify(orders, null, 4)}</pre> */}
    </div>
  )
}
