import { gql } from 'apollo-boost'

//id, UserID, intime, outtime, CreatedBy, CreatedOn, UpdatedBy, UpdatedOn, Username, PunchType, PunchDate, Token

export const GET_PUNCHCARD = gql`
  {
    punchcard {
      id
      UserID
      Intime
      Outtime
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
      Username
      PunchType
      PunchDate
      Token
    }
  }
`

export const PUNCHCARD_HISTORY = gql`
  {
    punchcardhistory {
      id
      UserID
      Intime
      Outtime
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
      Username
      PunchType
      PunchDate
      FirstName
      LastName
    }
  }
`

export const UPDATE_PUNCHCARD = gql`
  mutation UpdatePunchcard($id: ID, $Intime: String, $Outtime: String) {
    updatepunchcard(id: $id, Intime: $Intime, Outtime: $Outtime) {
      id
      UserID
      Intime
      Outtime
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
      Username
      PunchType
      PunchDate
    }
  }
`
export const INSERT_PUNCHCARD = gql`
  mutation InsertPunchcard($Intime: String, $Outtime: String) {
    insertpunchcard(Intime: $Intime, Outtime: $Outtime) {
      id
      UserID
      Intime
      Outtime
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
      Username
      PunchType
      PunchDate
    }
  }
`

export const REMOVE_PUNCHCARD = gql`
  mutation RemovePunchcard($id: ID!) {
    removepunchcard(id: $id)
  }
`

export const PUNCH_IN = gql`
  mutation PunchIn($Token: String, $PunchType: String) {
    punchin(Token: $Token, PunchType: $PunchType)
  }
`
