import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'

import {
  UPDATE_STOCK,
  CREATE_STOCK,
  GET_STOCKS,
  GET_STOCKTAKES,
  CREATE_STOCKTAKE,
  GET_STOCKLOCATIONS,
} from '../graphql/stock'
import { GET_BANKS } from '../graphql/bank'
import { GET_PRODUCTS } from '../graphql/product'
import { GET_BUYERS } from '../graphql/buyer'
import { CREATE_ORDERSTAFF } from '../graphql/order'

import ConfirmationDialog from '../common/confirmationDialog'

import TextField from '@material-ui/core/TextField'
import useForm from 'react-hook-form'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import TableHalf from '../common/tableHalf'

import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'
import { useSnackbar } from 'notistack'

import { GET_OFFICESTAFFS } from '../graphql/officestaff'
import LoginDialog from './loginDialog'

const restApi = '/api'

const dropdown = [
  { id: 'NEW_STOCK', title: 'New stock' },
  { id: 'STOCK_ADJUSTMENT', title: 'Stock take adjustment' },
  { id: 'OTHER', title: 'Other' },
  { id: 'RETURNED_STOCK', title: 'Returned stock' },
  /* { id: 'TRANSFERRED_IN', title: 'Transferred in' }, */
]
const locations = [{ id: 'SF_PUCHONG', title: 'SF Puchong' }]

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 150,
  },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'ProductName',
    label: 'Product',
  },
  {
    id: 'Quantity',
    label: 'Qty',
    textbox: true,
    width: 50,
  },
]

export default function OrderNewDialog(props) {
  const classes = useStyles()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [stock, setStock] = useState()

  const [confirm, setConfirm] = useState(false)
  const [paymentRemark, setPaymentRemark] = useState()
  const [paymentMode, setPaymentMode] = useState('CASH')
  const [bank, setBank] = useState()
  const [bankName, setBankName] = useState()
  const [bankCCName, setBankCCName] = useState()

  const [product, setProduct] = useState()
  const [qty, setQty] = useState(1)
  const [productName, setProductName] = useState()
  const [productFull, setProductFull] = useState()
  const [reason, setReason] = useState()
  const [reasonName, setReasonName] = useState()
  const [stockItems, setStockItems] = useState([])
  const [location, setlocation] = useState()
  const [selectedPrice, setSelectedPrice] = useState()
  const [staff, setStaff] = useState()

  const [checked, setChecked] = useState(true)
  const [customer, setCustomer] = useState('200')
  const [customerName, setCustomerName] = useState('')

  const [transactionType, setTransactionType] = useState('ADD')

  const { data: { products } = { products: [] } } = useQuery(GET_PRODUCTS)

  const { data: { banks } = { banks: [] } } = useQuery(GET_BANKS)

  const { data: { stocklocations } = { stocklocations: [] } } =
    useQuery(GET_STOCKLOCATIONS)

  const [staffName, setStaffName] = useState('')
  const [staffID, setStaffID] = useState()
  const [openLogin, setOpenLogin] = useState(false)

  const {
    loading,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  const { loading: loadingBuyers, data: { buyers } = { buyers: [] } } =
    useQuery(GET_BUYERS)

  const addCache = (cache, { data }) => {
    const latest = data.createorderstaff
    if (latest.Error) {
      enqueueSnackbar(latest.Error, {
        variant: 'error',
      })
      return
    }

    props.setOpen(false)
    props.setSelected([])
    props.setOrder()
    props.refetchOrder()
    props.refetchRemaining()
  }

  const [createorderstaff] = useMutation(CREATE_ORDERSTAFF, {
    update: addCache,
  })

  const onSubmit = (password) => {
    if (stockItems.length < 1) {
      enqueueSnackbar('Please add product', {
        variant: 'error',
      })
      return
    }

    if (paymentMode != 'CASH' && (!bank || !bankCCName)) {
      enqueueSnackbar('Please provide card details', {
        variant: 'error',
      })
      return
    }

    setOpenLogin(false)
    props.setOpen(false)

    const output = [
      ...stockItems.map((a) => {
        return {
          id: a.PriceID2,
          Title: a.ProductName,
          Qty: a.Quantity.toString(),
        }
      }),
    ]
    console.log(stockItems)

    //const locationValue = locations.find((a) => a.LocationName == location)

    createorderstaff({
      variables: {
        Customer: customer,
        Orders: output,
        SalesPerson: staff,
        Password: password,
        PaymentRemark: paymentRemark,
        PaymentMode: paymentMode,
        PaymentCCName: bankCCName,
        PaymentID: bank,
      },
    })
  }

  const handleClickAdd = () => {
    if (!selectedPrice) return

    if (stockItems.find((a) => a.id == selectedPrice)) return

    const productValue = products.find((item) =>
      item.ProductPrices.split(',').find(
        (a) => a.split('|')[2] == selectedPrice
      )
    )

    //console.log('first', productValue, selectedPrice)

    //selectedPrice
    productValue.Quantity = '1'

    const productPrices = productValue.ProductPrices.split(',').find(
      (a) => a.split('|')[2] == selectedPrice
    )

    const ProductName = `${productValue.ProductName} ${
      productPrices.split('|')[3]
    } - ${productPrices.split('|')[0]}`

    setStockItems([
      ...stockItems,
      { ...productValue, ProductName, PriceID2: selectedPrice },
    ])

    setProductName()
  }

  const handleUpdateTextbox = (value, row) => {
    if (!stockItems.find((a) => a.id == row.id)) return

    stockItems.map((a) => {
      if (a.id == row.id) {
        a.Quantity = value
      }
    })

    setStockItems([...stockItems])
  }

  const handleClickDelete = () => {
    setStockItems([...stockItems.filter((a) => a.id != stock.id)])
    setConfirm(false)

    setSelected([])
    setStock()
  }

  return (
    <React.Fragment>
      <LoginDialog
        staffID={staffID}
        setOpen={setOpenLogin}
        open={openLogin}
        action={onSubmit}
        title={`Password for ${staff}`}
        dialogTitle={`Password`}
      />
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove?"
        okButton="Yes"
        title="Continue remove"
      />
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="xs"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              New Order
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form>
              <DialogContent
                className={classes.dialogContent}
                style={{ paddingTop: 0 }}
              >
                <Grid container spacing={3}>
                  <Grid item md={8} xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => {
                            setChecked(!checked)
                            if (checked) setCustomer('200')
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Walk In Customer"
                    />
                    <br />
                    {!loadingBuyers && !checked && (
                      <AutocompleteField
                        name="buyer"
                        label="Customer"
                        value={customerName}
                        options={buyers
                          .filter((a) => a.UserID != 200)
                          .map(
                            (item) =>
                              `${item.Phone} — ${item.FirstName} ${item.LastName}`
                          )}
                        onChange={(e, value) => {
                          const buyerValue = buyers.find(
                            (item) =>
                              `${item.Phone} — ${item.FirstName} ${item.LastName}` ===
                              value
                          )
                          if (buyerValue) {
                            setCustomer(buyerValue.UserID)
                            setCustomerName(
                              `${buyerValue.Phone} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                            )
                          }
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.dividerroot} />
                <Grid container spacing={3}>
                  <Grid item md={4} xs={12}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        Select Payment Mode
                      </InputLabel>
                      <Select
                        value={paymentMode}
                        defaultValue={paymentMode}
                        onChange={(e) => {
                          setPaymentMode(e.target.value)
                        }}
                      >
                        <MenuItem value="CASH">Cash</MenuItem>
                        <MenuItem value="BANKTRANSFER">Bank Transfer</MenuItem>
                        <MenuItem value="CREDITCARD">Credit Card</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={1} xs={12}></Grid>
                  <Grid item md={4} xs={12}>
                    {paymentMode != 'CASH' && (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          Select Bank
                        </InputLabel>
                        <Select
                          label="Banks"
                          value={bank}
                          defaultValue={bank}
                          onChange={(e) => {
                            setBank(e.target.value)

                            const bankValue = banks.find(
                              (a) => a.BankID == e.target.value
                            )
                            if (bankValue) setBankName(bankValue.BankName)
                          }}
                        >
                          {banks.map((a, i) => (
                            <MenuItem key={i} value={a.BankID}>
                              {a.BankName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    {paymentMode != 'CASH' && (
                      <TextField
                        name="bankCCName"
                        label="Card Holder Name"
                        margin="dense"
                        value={bankCCName}
                        fullWidth
                        onChange={(e) => {
                          const value = e.target.value
                          setBankCCName(
                            value.replace(/[^a-zA-Z]/g, '').toUpperCase()
                          )
                        }}
                        autoComplete="off"
                        helperText="No space needed"
                      />
                    )}
                  </Grid>
                  <Grid item md={6} xs={12}>
                    {paymentMode != 'CASH' && (
                      <TextField
                        name="paymentRemark"
                        label="Payment Card No"
                        margin="dense"
                        value={paymentRemark}
                        fullWidth
                        onChange={(e) => {
                          const value = e.target.value
                          setPaymentRemark(value)
                        }}
                        autoComplete="off"
                      />
                    )}
                  </Grid>
                </Grid>
                <Divider className={classes.dividerroot} />
                <Grid container spacing={3}>
                  <Grid item md={8} xs={6}>
                    <Grid item md={12} xs={12}>
                      <AutocompleteField
                        name="ProductID"
                        label="Product"
                        options={products
                          /* .filter((item) => item.Category == '1') */
                          .map((item) => `${item.ProductName}`)}
                        onChange={(e, value) => {
                          const productValue = products.find(
                            (item) => `${item.ProductName}` === value
                          )

                          if (productValue) {
                            setProduct(productValue.id)
                            setProductName(`${productValue.ProductName}`)
                            setProductFull(productValue)
                            setSelectedPrice(
                              productValue.ProductPrices.split(',')[0].split(
                                '|'
                              )[2]
                            )
                          }
                        }}
                      />
                    </Grid>

                    <Grid item md={12} xs={12}>
                      {selectedPrice &&
                      productFull.ProductPrices &&
                      productFull.ProductPrices.split(',').length > 1 ? (
                        <div style={{ height: 50 }}>
                          <Select
                            value={selectedPrice}
                            onChange={(e) => {
                              setSelectedPrice(e.target.value)
                            }}
                            input={<BootstrapInput />}
                          >
                            {productFull.ProductPrices.split(',').map(
                              (price, i) => {
                                const priceitem = price.split('|')
                                return (
                                  <MenuItem key={i} value={priceitem[2]}>
                                    {priceitem[3]} - {priceitem[0]}
                                  </MenuItem>
                                )
                              }
                            )}
                          </Select>
                        </div>
                      ) : (
                        <div style={{ height: 50 }}>
                          {selectedPrice &&
                            productFull.ProductPrices &&
                            productFull.ProductPrices.split(',').length == 1 &&
                            productFull.ProductPrices.split(',')[0].split(
                              '|'
                            )[1]}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={6}>
                    <Button
                      style={{ marginTop: 35 }}
                      variant="contained"
                      disableElevation
                      color="primary"
                      type="button"
                      onClick={() => {
                        handleClickAdd()
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>

                <div style={{ marginTop: 0 }}>
                  {stockItems && stockItems.length > 0 && (
                    <TableHalf
                      updateTextbox={handleUpdateTextbox}
                      disableSelected={true}
                      clickOnSelect={false}
                      hidePagination={true}
                      hideChange={true}
                      hideDelete={false}
                      hideSearch={true}
                      setConfirm={setConfirm}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setStock}
                      tableData={stockItems}
                      setOpen={setOpen}
                      tableHead={tableHead}
                    ></TableHalf>
                  )}

                  {stockItems && stockItems.length == 0 && (
                    <div style={{ textAlign: 'center' }}>
                      Please add a product.
                    </div>
                  )}
                </div>
                {/* <Divider className={classes.dividerroot} /> */}
                <div style={{ marginTop: 50 }}>
                  <AutocompleteField
                    name="buyer"
                    label="Select Staff"
                    value={staffName}
                    options={officestaffs.map((item) =>
                      item.FirstName && item.LastName
                        ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                        : item.Username
                    )}
                    onChange={(e, value) => {
                      const buyerValue = officestaffs.find(
                        (item) =>
                          (item.FirstName && item.LastName
                            ? `${item.Username} — ${item.FirstName} ${item.LastName}`
                            : item.Username) === value
                      )
                      if (buyerValue) {
                        setStaffID(buyerValue.UserID)
                        setStaff(buyerValue.Username)
                        setStaffName(
                          buyerValue.FirstName && buyerValue.LastName
                            ? `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                            : buyerValue.Username
                        )
                      }
                    }}
                  />
                  <br />
                </div>
                <div style={{ marginBottom: 15, marginTop: 20 }}>
                  <Button
                    variant="contained"
                    disabled={stockItems <= 0 || !staff}
                    disableElevation
                    color="primary"
                    type="button"
                    onClick={() => {
                      setOpenLogin(true)
                    }}
                  >
                    Save
                  </Button>
                </div>
              </DialogContent>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
