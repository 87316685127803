import { gql } from 'apollo-boost'

export const TRANSACTION_HISTORY = gql`
  query TransactionHistory($FromDate: Date, $ToDate: Date, $Refund: String) {
    transactionhistory(FromDate: $FromDate, ToDate: $ToDate, Refund: $Refund) {
      id
      TransactionID
      TransactionRef
      TransactionDate
      TransactionAmount
      PaymentTo
      Active
      DebtorCode
      TransactionType
      TransactionDesc
      CreatedOn
      CreatedBy
      PaymentTermID
      ServiceTimes
      Qty
      UnitPrice
      RefundOn
      RefundBy
      RefundReason
      OrderNo
      DebitCredit
      TransactionLocation
      PaymentMode
      PaymentCCName
      PaymentCCNo
      BankName
      BuyerName
      Client
      Error
    }
  }
`

export const INSERT_TRANSACTION = gql`
  mutation InsertTransaction(
    $UserID: ID
    $SalesPerson: String
    $ProductID: ID
    $Qty: Int
    $PriceID: ID
  ) {
    inserttransaction(
      UserID: $UserID
      SalesPerson: $SalesPerson
      ProductID: $ProductID
      Qty: $Qty
      PriceID: $PriceID
    ) {
      id
      TransactionID
      TransactionRef
      TransactionDate
      TransactionAmount
      PaymentTo
      Active
      DebtorCode
      TransactionType
      TransactionDesc
      CreatedOn
      CreatedBy
      PaymentTermID
      ServiceTimes
      Qty
      UnitPrice
      RefundOn
      RefundBy
      RefundReason
      OrderNo
      DebitCredit
      TransactionLocation
      PaymentMode
      PaymentCCName
      PaymentCCNo
      BankName
      BuyerName
      Client
      Error
    }
  }
`

export const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction($id: ID, $RefundReason: String) {
    updatetransaction(id: $id, RefundReason: $RefundReason) {
      id
      TransactionID
      TransactionRef
      TransactionDate
      TransactionAmount
      PaymentTo
      Active
      DebtorCode
      TransactionType
      TransactionDesc
      CreatedOn
      CreatedBy
      PaymentTermID
      ServiceTimes
      Qty
      UnitPrice
      RefundOn
      RefundBy
      RefundReason
      OrderNo
      DebitCredit
      TransactionLocation
      PaymentMode
      PaymentCCName
      PaymentCCNo
      BankName
      BuyerName
      Client
      Error
    }
  }
`
