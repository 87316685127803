import React, { useState, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter,
  Switch,
  withRouter,
  Link,
  Link as Link2,
} from 'react-router-dom'

import clsx from 'clsx'

import Cookies from 'js-cookie'
import Login from './Login'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBox,
  faFish,
  faUsers,
  faUserNurse,
  faShoppingCart,
  faPrint,
  faChartLine,
  faUserTie,
  faHeartbeat,
  faTruck,
  faPlane,
  faFileInvoiceDollar,
  faCog,
  faUser,
  faCalculator,
  faPowerOff,
  faFileAlt,
  faFolderOpen,
  faDollarSign,
  faStopwatch,
  faPlaneDeparture,
  faChartPie,
  faIdCard,
  faCartArrowDown,
  faHome,
} from '@fortawesome/free-solid-svg-icons'

import axios from 'axios'
import './App.css'

import {
  fade,
  makeStyles,
  useTheme,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import StorefrontIcon from '@material-ui/icons/Storefront'
import FaceIcon from '@material-ui/icons/Face'

import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import Tooltip from '@material-ui/core/Tooltip'
import { GET_SHOPCONFIG } from './components/graphql/config'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import AppSearch from './AppSearch'

import { SnackbarProvider } from 'notistack'
import Logout from './Logout'

import MyProducts from './components/admin/productmain'
import ShopDecoration from './components/admin/shopDecoration'
import Config from './components/admin/config'
import BasicSettings from './components/admin/basicsettings'
import Leave from './components/admin/leave'
import Member from './components/admin/member'
import Officestaff from './components/admin/officestaffmain'
import Punchcard from './components/admin/punchcard'
import HealthReport from './components/admin/healthreport'
import HealthReportMain from './components/admin/healthreportmain'
import Order from './components/admin/order'
import OrderDetails from './components/admin/orderDetails'
import StockMain from './components/admin/stockmain'
import MemberMain from './components/admin/membermain'
import BookingDetails from './components/admin/bookingDetails'
import PunchCardMain from './components/admin/punchcardmain'
import TransactionMain from './components/admin/transactionmain'
import OrderMain from './components/admin/ordermain'
import ConfigMain from './components/admin/configmain'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`
const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    flexGrow: 1,
    padding: 0,
    textAlign: 'left',
    lineHeight: 1,
    textDecoration: 'none',
  },
  toolBar2: {
    margin: 'auto',
    maxWidth: 1199,
    width: '100%',
    minHeight: 48,
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: 'white',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('md')]: {
      minHeight: 30,
      backgroundColor: 'transparent',
      color: 'white',
    },
  },
  appBar: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  link: { color: (props) => props.COLORPRIMARY, textDecoration: 'none' },
  inline: {
    display: 'inline',
    color: (props) => props.COLORPRIMARY,
  },
  drawer: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
    },
  },
  drawerOpen: {
    background: '#cfd8dc!important',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    background: '#cfd8dc!important',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    padding: theme.spacing(6, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  appbarroot: {
    position: 'fixed',
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(7) + 1,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  icon: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  iconList: {
    color: '#757575',
    minWidth: '26px',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
  },
  menuItem: {
    paddingLeft: 10,
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: 'white',
  },
  signinMenu: {
    paddingRight: 10,
    textDecoration: 'none',
    fontWeight: 'bold',
    color: (props) => props.COLORPRIMARY,
    [theme.breakpoints.up('sm')]: {
      color: 'white',
    },
  },
  menuDivider: {
    height: 30,
    borderLeft: '1px solid rgba(255, 255, 255, 0)',
  },
  listitemroot: {
    minWidth: 250,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}))

export default function App(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const [signedIn, setSignedIn] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [redirectTo, setRedirectTo] = useState()

  const [open, setOpen] = useState(false)

  const [theme, setTheme] = useState({
    palette: {
      primary: {
        dark: '#263238',
        main: '#263238',
        paper: '#efefef',
        contrastText: '#fff',
      },
      secondary: {
        main: '#ddd',
      },
      background: {
        default: '#efefef',
      },
      type: 'light', // Switching the dark mode on is a single property value change.
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    transitions: {
      duration: {
        shortest: 20,
        shorter: 60,
        short: 100,
        standard: 100,
        complex: 175,
        enteringScreen: 125,
        leavingScreen: 95,
      },
    },
  })

  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: [] },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const classes = useStyles(shopconfig)

  const theme2 = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [dense, setDense] = React.useState(false)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const {
    data: { isDarkModeEnabled, loginDetails } = {
      isDarkModeEnabled: false,
    },
    client,
  } = useQuery(GET_DARKMODE)

  const handleToggle = () => {
    toggleDarkTheme(!isDarkModeEnabled)
    setDense(!isDarkModeEnabled)
    client.writeData({ data: { isDarkModeEnabled: !isDarkModeEnabled } })
  }

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [side]: open })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const menus = [
    /* { title: 'Home', link: '/', icon: <HomeIcon /> }, */

    {
      title: 'Manage',
      link: '/order',
      icon: <FontAwesomeIcon icon={faHome} classes={classes.icon} />,
      access: ['Management', 'Admin', 'Spa Consultant', 'Branch Manager'],
    },
    {
      title: 'Member',
      link: '/member',
      icon: <FontAwesomeIcon icon={faUsers} classes={classes.icon} />,
      access: ['Management', 'Admin', 'Spa Consultant', 'Branch Manager'],
    },
    {
      title: 'Health Report',
      link: '/healthreport',
      icon: <FontAwesomeIcon icon={faHeartbeat} classes={classes.icon} />,
      access: ['Management', 'Spa Consultant', 'Branch Manager', 'Admin'],
    },
    {
      title: 'Staff',
      link: '/officestaff',
      icon: <FontAwesomeIcon icon={faUserNurse} classes={classes.icon} />,
      access: ['Management', 'Admin', 'HR'],
    } /* ,
    {
      title: 'Attendance',
      link: '/attendance',
      icon: <FontAwesomeIcon icon={faStopwatch} classes={classes.icon} />,
      access: ['Management', 'Attendance', 'Staff', 'Supervisor'],
    } */ /* ,
    {
      title: 'Leave',
      link: '/leave',
      icon: <FontAwesomeIcon icon={faPlaneDeparture} classes={classes.icon} />,
      access: ['Management', 'Staff', 'Supervisor'],
    } */,
    {
      title: 'Stock',
      link: '/stock',
      icon: <FontAwesomeIcon icon={faChartPie} classes={classes.icon} />,
      access: [
        'Finance',
        'Spa Consultant',
        'Branch Manager',
        'Admin',
        'Management',
      ],
    },
    {
      title: 'Sales Report',
      link: '/salesreport',
      icon: <FontAwesomeIcon icon={faDollarSign} classes={classes.icon} />,
      access: [
        'Finance',
        'Spa Consultant',
        'Branch Manager',
        'Admin',
        'Management',
      ],
    },
    {
      title: 'Config',
      link: '/config',
      icon: <FontAwesomeIcon icon={faCog} classes={classes.icon} />,
      access: ['Management', 'Admin'],
    },
  ]

  const sideList = (side) => (
    <div>
      <div>
        <IconButton onClick={handleDrawerClose}>
          {theme2.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      {/* 
      <Divider className={classes.divider} />
      <Grid item xs={12} md={12}>
        <div className={classes.demo}>
          <List dense={true}>
            <ListItem>
              <ListItemIcon>
                <div
                  className={classes.userIcon}
                  dangerouslySetInnerHTML={{
                    __html: `<svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" style="enable-background:new 0 0 100 256;" version="1.1" viewBox="0 30 250 256" x="0px" xmlns="http://www.w3.org/2000/svg" y="0px"><path class="st6" d="M93.09,126.1c0,0-0.01,0-0.01,0c-16.82,0-30.51-13.68-30.51-30.51c0-16.82,13.69-30.51,30.51-30.51
                            s30.51,13.69,30.51,30.51c0,5.3-1.38,10.51-3.98,15.07c-2.01,3.54-0.78,8.04,2.76,10.06c3.54,2.01,8.04,0.78,10.05-2.76
                            c3.87-6.78,5.91-14.52,5.91-22.37c0-24.95-20.3-45.26-45.26-45.26s-45.26,20.3-45.26,45.26c0,24.95,20.29,45.25,45.23,45.26
                            c0.01,0,0.01,0,0.02,0c31.67,0,57.44,25.76,57.44,57.44c0,4.07,3.3,7.37,7.37,7.37c4.07,0,7.37-3.3,7.37-7.37
                            C165.26,158.49,132.88,126.11,93.09,126.1z" fill="#bdbdbd"></path><path class="st6" d="M54.31,137.39c-20.93,13.35-33.42,36.11-33.42,60.9c0,4.07,3.3,7.37,7.37,7.37c4.07,0,7.37-3.3,7.37-7.37
                            c0-19.73,9.94-37.85,26.6-48.47c3.43-2.18,4.44-6.74,2.25-10.18C62.3,136.21,57.74,135.2,54.31,137.39z" fill="#37474f"></path></svg>`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={Cookies.get('StaffID')}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                    >
                      {Cookies.get('Department').replace('Booking ', '')}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          </List>
        </div>
      </Grid> */}

      <Divider className={classes.divider} />
      <List>
        {menus.map((menu, index) => {
          const access = menu.access.find(
            (access) => access == Cookies.get('Department')
          )
          if (access)
            return (
              <Link
                className={classes.link}
                to={menu.link}
                onClick={() => {
                  setOpen(false)
                }}
                key={index}
              >
                <ListItem button key={index}>
                  <Tooltip title={menu.title}>
                    <ListItemIcon className={classes.iconList}>
                      {menu.icon}
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary={menu.title} />
                </ListItem>
              </Link>
            )
        })}
      </List>
      <Divider className={classes.divider} />
      <List>
        <Link
          onClick={() => {
            setOpen(false)
          }}
          to={`/logout`}
          style={{ color: '#37474f', textDecoration: 'none' }}
        >
          <ListItem button key={1001}>
            <ListItemIcon className={classes.iconList}>
              <FontAwesomeIcon icon={faPowerOff} style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </Link>
      </List>
      <Divider className={classes.divider} />
    </div>
  )

  // we change the palette type of the theme in state
  const toggleDarkTheme = (isDarkTheme) => {
    if (isDarkTheme) {
      setTheme({
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#212121',
          },
          type: 'dark', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    } else {
      setTheme({
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
      })
    }
  }

  const muiTheme = createMuiTheme(theme)

  useEffect(() => {
    if (shopconfig.COLORPRIMARY)
      setTheme({
        palette: {
          primary: {
            dark: shopconfig.COLORPRIMARY,
            main: shopconfig.COLORPRIMARY,
            paper: '#f5f5f5',
            contrastText: '#f3f3f3',
          },
          secondary: {
            main: shopconfig.COLORPRIMARY,
          },
          background: {
            default: '#efefef',
          },
          type: 'light', // Switching the dark mode on is a single property value change.
        },
        typography: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: 12,
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
        },
        transitions: {
          duration: {
            shortest: 20,
            shorter: 60,
            short: 100,
            standard: 100,
            complex: 175,
            enteringScreen: 125,
            leavingScreen: 95,
          },
        },
      })
    //if (shopconfig.COMSHO) document.title = `Welcome to ${shopconfig.COMSHO}`
  }, [shopconfig])

  const handleSignedIn = (link) => {
    setSignedIn(true)
    setRedirect(true)
    setRedirectTo(link)
  }

  const renderRedirect = () => {
    if (redirect) {
      setRedirect(false)
      return <Redirect to={redirectTo} />
    }
    if (redirectTo) return <Redirect to={redirectTo} />
  }

  useEffect(() => {
    if (Cookies.get('signedin')) {
      setSignedIn(true)
      axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get(
        'token'
      )}`
      client.writeData({
        data: {
          isDarkModeEnabled,
          loginDetails: {
            StaffID: Cookies.get('StaffID'),
            Department: Cookies.get('Department'),
          },
        },
      })

      console.log('staff', Cookies.get('StaffID'))
    }
  }, [])

  return (
    <ThemeProvider theme={muiTheme}>
      <SnackbarProvider>
        <BrowserRouter>
          {renderRedirect()}

          <div className={classes.root}>
            <CssBaseline />
            {signedIn && (
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar variant="dense" style={{ paddingLeft: 20 }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography className={classes.title} variant="h6" noWrap>
                    {shopconfig.COMSHO}
                  </Typography>

                  {/* <FormControlLabel
                control={<SwitchMUI checked={dense} onChange={handleToggle} />}
                label="test"
              /> */}
                  {/* {!['Pickup'].find(
                    (id) => id == Cookies.get('Department')
                  ) && <AppSearch redirect={handleSignedIn} />} */}
                </Toolbar>
              </AppBar>
            )}
            {signedIn && (
              <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Divider />
                  <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: open,
                      [classes.drawerClose]: !open,
                    })}
                    classes={{
                      paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                      }),
                    }}
                  >
                    {sideList('left')}
                  </Drawer>
                  <Divider className={classes.divider} />
                </Hidden>
              </nav>
            )}
            <main className={signedIn ? classes.content : null}>
              <div className={signedIn ? classes.toolbar : null}>
                <Switch>
                  <Route
                    exact
                    path="/"
                    render={(a) => {
                      if (signedIn) return <OrderMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/myproducts"
                    render={(a) => {
                      if (signedIn) return <MyProducts />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/leave"
                    render={(a) => {
                      if (signedIn) return <Leave />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/member"
                    render={(a) => {
                      if (signedIn) return <MemberMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/officestaff"
                    render={(a) => {
                      if (signedIn) return <Officestaff />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/attendance"
                    render={(a) => {
                      if (signedIn) return <PunchCardMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/salesreport"
                    render={(a) => {
                      if (signedIn) return <TransactionMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/config"
                    render={(a) => {
                      if (signedIn) return <ConfigMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />

                  <Route
                    path="/order"
                    render={(a) => {
                      if (signedIn) return <OrderMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />

                  <Route path="/orderdetails/:token" component={OrderDetails} />
                  <Route
                    path="/bookingdetails/:token"
                    component={BookingDetails}
                  />

                  <Route
                    path="/stock"
                    render={(a) => {
                      if (signedIn) return <StockMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/healthreport"
                    render={(a) => {
                      if (signedIn) return <HealthReportMain />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />

                  <Route
                    path="/shopdecoration"
                    render={(a) => {
                      if (signedIn)
                        return <ShopDecoration {...a} setTheme={setTheme} />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/basicsettings"
                    render={(a) => {
                      if (signedIn) return <BasicSettings />
                      else return <Login {...a} signedIn={setSignedIn} />
                    }}
                  />
                  <Route
                    path="/login"
                    render={(a) => <Login {...a} signedIn={setSignedIn} />}
                  />
                  <Route
                    path="/logout"
                    render={(a) => <Logout signedIn={setSignedIn} />}
                  />
                  {/* <Route
                  path="/admin"
                  render={(a) => <Admin {...a} setTheme={setTheme} />}
                ></Route> */}
                </Switch>
              </div>
            </main>
          </div>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  )
}
