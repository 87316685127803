import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TableHalf from '../common/tableHalf'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'
import Moment from 'moment'
import OfficeStaffPayrollDetailFormDialog from './officestaffPayrollDetailsFormDialog'

import { UPDATE_STOCK, CREATE_STOCK, GET_STOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import {
  GET_PAYROLLS,
  INSERT_PAYROLL,
  REMOVE_PAYROLL,
  UPDATE_PAYROLL,
  GET_PAYROLLORDERS,
  GET_PAYROLLDETAILS,
  GET_PAYROLLCOLLECTIONS,
  GET_PAYROLLBOOKINGS,
  GET_PAYROLLRATINGS,
} from '../graphql/payroll'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'
import GetAppIcon from '@material-ui/icons/GetApp'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'

const restApi = '/api'

const restApi2 = 'https://frapp.cloud/fishfarm'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'CreatedDate',
    date: true,
    label: 'Sales Date',
  },
  {
    id: 'OrderNo',
    label: 'Order No',
  },
  {
    id: 'ProductName',
    label: 'Product Name',
  },
  {
    id: 'InvoiceAmount',
    currency: true,
    label: 'Sales Total',
  },
]

const tableHeadDetails = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'Title',
    label: 'Title',
  },
  {
    id: 'Amount',
    currency: true,
    label: 'Amount',
  },
]

const tableHeadCollection = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'ReceivedOn',
    date: true,
    label: 'Collection Date',
  },
  {
    id: 'OrderNo',
    label: 'Order No',
  },
  {
    id: 'ReceivedBy',
    label: 'Serviced By',
  },
]

const tableHeadBooking = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'ReceivedOn',
    date: true,
    label: 'Booking Date',
  },
  {
    id: 'CreatedBy',
    label: 'Mobile',
  },
  {
    id: 'FirstName',
    label: 'First Name',
  },
  {
    id: 'LastName',
    label: 'Last Name',
  },
  {
    id: 'ReceivedBy',
    label: 'Serviced By',
  },
]

const tableHeadRating = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'RatingOn',
    date: true,
    label: 'Rating Date',
  },
  {
    id: 'Rating',
    label: 'Rating',
  },
  {
    id: 'CreatedBy',
    label: 'Mobile',
  },
  {
    id: 'FirstName',
    label: 'First Name',
  },
  {
    id: 'LastName',
    label: 'Last Name',
  },
  {
    id: 'ReceivedBy',
    label: 'Serviced By',
  },
]

export default function OfficeStaffPayrollDetailDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const [selected, setSelected] = useState([])
  const [selectedDetails, setSelectedDetails] = useState([])
  const [order, setOrder] = useState()
  const [payrollDetail, setPayrollDetail] = useState()
  const [confirm, setConfirm] = useState(false)
  const [open, setOpen] = useState(false)
  const [openOrder, setOpenOrder] = useState(false)

  const { data: { payrollorders } = { payrollorders: [] } } = useQuery(
    GET_PAYROLLORDERS,
    {
      variables: {
        UserID: props.data && props.data.id,
        FromDate: props.data && props.from,
        ToDate: props.data && props.to,
      },
      skip: !props.data,
    }
  )

  const { data: { payrollcollections } = { payrollcollections: [] } } =
    useQuery(GET_PAYROLLCOLLECTIONS, {
      variables: {
        UserID: props.data && props.data.id,
        FromDate: props.data && props.from,
        ToDate: props.data && props.to,
      },

      skip: !props.data,
    })

  const { data: { payrollbookings } = { payrollbookings: [] } } = useQuery(
    GET_PAYROLLBOOKINGS,
    {
      variables: {
        UserID: props.data && props.data.id,
        FromDate: props.data && props.from,
        ToDate: props.data && props.to,
      },

      skip: !props.data,
    }
  )

  const { data: { payrollratings } = { payrollratings: [] } } = useQuery(
    GET_PAYROLLRATINGS,
    {
      variables: {
        UserID: props.data && props.data.id,
        FromDate: props.data && props.from,
        ToDate: props.data && props.to,
      },

      skip: !props.data,
    }
  )
  /* 
  const {
    data: { payrolldetails } = { payrolldetails: [] },
    refetch,
  } = useQuery(GET_PAYROLLDETAILS, {
    variables: {
      PayrollID: props.data && props.data && props.data.id,
    },
  })
 */
  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.data && props.data.id,
      },
    })
    const latest = data.insertpayrollheader
    cache.writeQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.data && props.data.id,
      },
      data: { payrollheaders: [latest, ...old.payrollheaders] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setPayroll()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.data && props.data.id,
      },
    })

    const latest = data.updatepayrollheader

    const foundIndex = old.payrollheaders.findIndex(
      (item) => item.id === latest.id
    )
    old.payrollheaders.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.data && props.data.id,
      },
      data: { payrollheaders: [...old.payrollheaders] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setPayroll()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PAYROLLS,
      variables: {
        UserID: props.data && props.data.id,
      },
    })
    if (data.removepayrollheader) {
      const latest = old.payrollheaders.filter(
        (item) => item.id !== props.data && props.data.id
      )
      cache.writeQuery({
        query: GET_PAYROLLS,
        variables: {
          UserID: props.data && props.data.id,
        },
        data: { payrollheaders: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setPayroll()
    }
  }

  const [insertPayroll] = useMutation(INSERT_PAYROLL, { update: addCache })
  const [updatePayroll] = useMutation(UPDATE_PAYROLL, {
    update: updateCache,
  })
  const [removePayroll] = useMutation(REMOVE_PAYROLL, {
    update: deleteCache,
  })
  /* 
  const handleClickDelete = () => {
    removePayroll({
      variables: {
        id: props.data && props.data.id,
      },
    })
    setConfirm(false)
  } */

  const onSubmit = (values) => {
    if (props.data && props.data && props.data.id) {
      /* updatePayroll({
        variables: {
          UserID: props.data && props.data.id,
          id: props.data && props.data.id,
          From: from,
          To: to,
        },
      }) */
    } else {
      /* insertPayroll({
        variables: {
          UserID: props.data && props.data.id,
          From: from,
          To: to,
        },
      }) */
    }
  }

  const handleSaveCsv = () => {
    const fromDate = props.from.split('/')
    const toDate = props.to.split('/')

    console.log(fromDate, toDate)
    window.open(
      `${restApi2}/staffsales_csv.php?from=${props.from}&to=${props.to}&staff=${
        props.data && props.data.id
      }`,
      '_blank'
    )
  }

  useEffect(() => {
    if (!props.data) return
  }, [props])

  return (
    <React.Fragment>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Payroll item?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Sales Report
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <h2 style={{ marginBottom: 0, marginTop: 0 }}>
                  {props.data && props.data.StaffID}
                </h2>
                <h3 style={{ marginTop: 0, marginBottom: 30 }}>
                  {props.data && Moment(props.from).format('DD/MM/YYYY')} -{' '}
                  {props.data && Moment(props.to).format('DD/MM/YYYY')}
                </h3>

                <Grid container spacing={3}>
                  {payrollorders.length > 0 && (
                    <Grid
                      item
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      md={12}
                      xs={12}
                    >
                      <h3 style={{ marginBottom: 0 }}>Sales</h3>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        startIcon={<GetAppIcon />}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        onClick={() => {
                          handleSaveCsv()
                        }}
                      >
                        Save CSV
                      </Button>
                      <TableHalf
                        disableSelected={true}
                        clickOnSelect={true}
                        hideSearch={true}
                        hideDelete={true}
                        hideChange={true}
                        selected={selected}
                        setSelected={setSelected}
                        tableState={setOrder}
                        setOpen={setOpenOrder}
                        tableData={payrollorders}
                        tableHead={tableHead}
                        rowsPerPage={5}
                      ></TableHalf>
                    </Grid>
                  )}
                  {payrollcollections.length > 0 && (
                    <Grid
                      item
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      md={12}
                      xs={12}
                    >
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Customer Service - Collection
                      </h3>
                      <TableHalf
                        disableSelected={true}
                        clickOnSelect={true}
                        hideSearch={true}
                        hideDelete={true}
                        hideChange={true}
                        selected={selected}
                        setSelected={setSelected}
                        tableState={setOrder}
                        setOpen={setOpenOrder}
                        tableData={payrollcollections}
                        tableHead={tableHeadCollection}
                        rowsPerPage={5}
                      ></TableHalf>
                    </Grid>
                  )}
                  {payrollbookings.length > 0 && (
                    <Grid
                      item
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      md={12}
                      xs={12}
                    >
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Customer Service - Booking
                      </h3>
                      <TableHalf
                        disableSelected={true}
                        clickOnSelect={true}
                        hideSearch={true}
                        hideDelete={true}
                        hideChange={true}
                        selected={selected}
                        setSelected={setSelected}
                        tableState={setOrder}
                        setOpen={setOpenOrder}
                        tableData={payrollbookings}
                        tableHead={tableHeadBooking}
                        rowsPerPage={5}
                      ></TableHalf>
                    </Grid>
                  )}
                  {payrollratings.length > 0 && (
                    <Grid
                      item
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      md={12}
                      xs={12}
                    >
                      <h3 style={{ marginTop: 0, marginBottom: 0 }}>
                        Customer Service - Rating
                      </h3>
                      <TableHalf
                        disableSelected={true}
                        clickOnSelect={true}
                        hideSearch={true}
                        hideDelete={true}
                        hideChange={true}
                        selected={selected}
                        setSelected={setSelected}
                        tableState={setOrder}
                        setOpen={setOpenOrder}
                        tableData={payrollratings}
                        tableHead={tableHeadRating}
                        rowsPerPage={5}
                      ></TableHalf>
                    </Grid>
                  )}
                  {/* <Grid item md={12} xs={12}>
                    <Divider className={classes.dividerroot} />
                    <OfficeStaffPayrollDetailFormDialog
                      open={open}
                      payrollID={props.data && props.data && props.data.id}
                      data={payrollDetail}
                      setOpen={setOpen}
                      setSelected={setSelected}
                    />
                    <Button
                      variant="contained"
                      style={{ marginBottom: 10 }}
                      classes={{ root: classes.newButton }}
                      color="primary"
                      onClick={() => {
                        setOpen(true)
                      }}
                    >
                      New
                    </Button>
                    <TableHalf
                      clickOnSelect={true}
                      hideSearch={true}
                      hideDelete={true}
                      hideChange={true}
                      selected={selectedDetails}
                      setSelected={setSelectedDetails}
                      tableState={setPayrollDetail}
                      tableData={payrolldetails}
                      setOpen={setOpen}
                      tableHead={tableHeadDetails}
                    ></TableHalf>
                  </Grid> */}
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {/* {props.data && (
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton>
                )} */}
                {/* <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button> */}
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
