import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'

import { UPDATE_STOCK, CREATE_STOCK, GET_STOCKS } from '../graphql/stock'
import { GET_PRODUCTS } from '../graphql/product'
import {
  GET_CARTS,
  INSERT_CART,
  REMOVE_CART,
  UPDATE_CART,
} from '../graphql/cart'
import { GET_OFFICESTAFFS } from '../graphql/officestaff'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'

const restApi = '/api'

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.grey[200],
    border: 1,
    borderColor: theme.palette.primary.main,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
  },
}))(InputBase)

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function CartDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()

  const [confirm, setConfirm] = useState(false)
  const [qty, setQty] = useState(1)
  const [productFull, setProductFull] = useState()
  const [product, setProduct] = useState()
  const [productName, setProductName] = useState()
  const [price, setPrice] = useState()
  const [selectedPrice, setSelectedPrice] = useState()

  const [staff, setStaff] = useState()

  const { data: { products } = { products: [] } } = useQuery(GET_PRODUCTS)

  const {
    loading: loadingStaff,
    data: { officestaffs } = { officestaffs: [] },
    refetch: refetchOfficestaff,
  } = useQuery(GET_OFFICESTAFFS)

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
      variables: {
        UserID: props.customer,
      },
    })
    const latest = data.insertcart
    cache.writeQuery({
      query: GET_CARTS,
      variables: {
        UserID: props.customer,
      },
      data: { carts: [latest, ...old.carts] },
    })
    props.setOpen(false)
    props.setSelected([])
    props.setCart()
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
      variables: {
        UserID: props.customer,
      },
    })

    const latest = data.updatecart

    const foundIndex = old.carts.findIndex((item) => item.id === latest.id)
    old.carts.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_CARTS,
      variables: {
        UserID: props.customer,
      },
      data: { carts: [...old.carts] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setCart()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_CARTS,
      variables: {
        UserID: props.customer,
      },
    })
    if (data.removecart) {
      const latest = old.carts.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_CARTS,
        variables: {
          UserID: props.customer,
        },
        data: { carts: [...latest] },
      })

      props.setOpen(false)
      props.setSelected([])
      props.setCart()
    }
  }

  const [insertCart] = useMutation(INSERT_CART, { update: addCache })
  const [updateCart] = useMutation(UPDATE_CART, {
    update: updateCache,
  })
  const [removeCart] = useMutation(REMOVE_CART, {
    update: deleteCache,
  })

  const handleClickDelete = () => {
    removeCart({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
  }

  useEffect(() => {
    if (!props.data) return

    setProduct(`${props.data.ProductID}`)
    setProductName(`${props.data.ProductName}`)
  }, [props])

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateCart({
        variables: {
          UserID: props.customer,
          id: props.data.id,
          ProductID: product,
          PriceID: selectedPrice,
          Qty: parseInt(qty),
        },
      })
    } else {
      insertCart({
        variables: {
          UserID: props.customer,
          SalesPerson: staff,
          ProductID: product,
          PriceID: selectedPrice,
          Qty: parseInt(qty),
        },
      })
    }
  }
  useEffect(() => {
    //console.log(props)
    if (!props.data) return
    setSelectedPrice(props.data.PriceID)
    setProductFull(props.data)
    setQty(props.data.Qty)
  }, [props])

  return (
    <React.Fragment>
      {
        <ConfirmationDialog
          action={handleClickDelete}
          confirm={confirm}
          setConfirm={setConfirm}
          message="Continue remove Cart item?"
          okButton="Yes"
          title="Continue remove"
        />
      }
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Cart
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    {!loadingStaff && !props.data && (
                      <AutocompleteField
                        name="Staff"
                        label="Staff"
                        value={staff}
                        options={officestaffs.map((item) =>
                          item.Username ? item.Username : ''
                        )}
                        onChange={(e, value) => {
                          if (value) {
                            setStaff(value)
                          }
                        }}
                      />
                    )}
                    {props.data && (
                      <div>
                        Sales Person : <strong>{props.data.SalesPerson}</strong>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <AutocompleteField
                      name="ProductID"
                      label="Product"
                      value={productName}
                      options={products
                        /* .filter((item) => item.Category == '1') */
                        .map((item) => `${item.ProductName}`)}
                      onChange={(e, value) => {
                        const productValue = products.find(
                          (item) => `${item.ProductName}` === value
                        )
                        if (productValue) {
                          setProduct(productValue.id)
                          setProductName(`${productValue.ProductName}`)
                          setProductFull(productValue)
                          setSelectedPrice(
                            productValue.ProductPrices.split(',')[0].split(
                              '|'
                            )[2]
                          )
                        }
                      }}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    {productFull && productFull.Category == '1' && (
                      <TextField
                        name="Qty"
                        label="Qty"
                        margin="dense"
                        fullWidth
                        defaultValue={qty}
                        style={{ width: 120 }}
                        onChange={(e) => {
                          setQty(e.target.value)
                        }}
                        autoComplete="off"
                        type="number"
                        inputRef={register({ required: 'Required' })}
                        error={errors.Qty}
                        helperText={errors.Qty && errors.Qty.message}
                      />
                    )}
                  </Grid>

                  <Grid item md={12} xs={12}>
                    {selectedPrice &&
                      productFull.ProductPrices &&
                      productFull.ProductPrices.split(',').length > 1 && (
                        <React.Fragment>
                          <Select
                            defaultValue={selectedPrice}
                            value={selectedPrice}
                            onChange={(e) => {
                              setSelectedPrice(e.target.value)
                            }}
                            input={<BootstrapInput />}
                          >
                            {productFull.ProductPrices.split(',').map(
                              (price, i) => {
                                const priceitem = price.split('|')
                                return (
                                  <MenuItem key={i} value={priceitem[2]}>
                                    {priceitem[3]} - {priceitem[0]}
                                  </MenuItem>
                                )
                              }
                            )}
                          </Select>
                        </React.Fragment>
                      )}
                    {selectedPrice &&
                      productFull.ProductPrices &&
                      productFull.ProductPrices.split(',').length == 1 &&
                      productFull.ProductPrices.split(',')[0].split('|')[1]}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                {props.data && (
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
                <Button
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
