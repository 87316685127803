import { gql } from 'apollo-boost'

export const GET_MEMBERS = gql`
  query Members($Branch: ID) {
    members(Branch: $Branch) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
      ServiceTimes
      Client
    }
  }
`

export const GET_MEMBERORDERS = gql`
  query MemberOrders($UserID: ID) {
    memberorders(UserID: $UserID) {
      id
      OrderID
      OrderNo
      UserID
      ProductID
      InvoiceAmount
      Qty
      CreatedDate
      PriceID
      UnitPrice
      ProductName
      Category
      ProductImage
    }
  }
`

export const UPDATE_MEMBER = gql`
  mutation UpdateMember(
    $id: ID
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Password: String
  ) {
    updatemember(
      id: $id
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Password: $Password
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
      ServiceTimes
      Client
    }
  }
`
export const INSERT_MEMBER = gql`
  mutation InsertMember(
    $Email: String
    $Phone: String
    $FirstName: String
    $LastName: String
    $Address1: String
    $Address2: String
    $City: String
    $Postcode: String
    $State: String
    $Country: String
    $Username: String
    $Password: String
  ) {
    insertmember(
      Email: $Email
      Phone: $Phone
      FirstName: $FirstName
      LastName: $LastName
      Address1: $Address1
      Address2: $Address2
      City: $City
      Postcode: $Postcode
      State: $State
      Country: $Country
      Username: $Username
      Password: $Password
    ) {
      id
      UserID
      Email
      Phone
      FirstName
      LastName
      Address1
      Address2
      City
      Postcode
      State
      Country
      CreatedOn
      LastUpdated
      Username
      Error
      ServiceTimes
      Client
    }
  }
`

export const REMOVE_MEMBER = gql`
  mutation RemoveMember($id: ID!) {
    removemember(id: $id)
  }
`
