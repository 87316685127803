import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import { GET_CARTS } from '../graphql/cart'
import { GET_SHOPCONFIG } from '../graphql/config'
import Cookies from 'js-cookie'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import { useMutation, useQuery } from '@apollo/react-hooks'
import axios from 'axios'

const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogContentRoot: { padding: 0 },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />
})

export default function ReportDialog(props) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [filename, setFilename] = React.useState()
  const tweetersIframe = `<iframe width="100%" style="height:calc(100vh - 200px); border:0" height="100%" border="0" src="${restApi}/media/spa1/uploads/healthreport/${props.data.Filename}" />`

  useEffect(() => {
    /* axios.interceptors.response.use(function (response) {
      const ctype = response.headers["content-type"];
      response.data = ctype.includes("charset=GB2312") ?
        iconv.decode(response.data, 'gb2312') :
        iconv.decode(response.data, 'utf-8');
      return response;
    })  */

    axios
      .get(
        `${restApi}/media/spa1/uploads/healthreport/${props.data.Filename}`,
        {
          headers: {
            'Content-Type': 'text/html;charset=gb2312',
          },
        }
      )
      .then((response) => {
        setFilename(response.data)
        console.log(response)
      })
      .catch((error) => console.log(error))
  }, [props])

  const handleClose = () => {
    props.setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="order-dialog"
      >
        <DialogTitle className={classes.dialogTitle} id="order-dialog">
          {props.data.Title}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              props.setOpen(false)
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent classes={{ root: classes.dialogContentRoot }}>
          {/* <ReportChart /> */}
          <div dangerouslySetInnerHTML={{ __html: tweetersIframe }}></div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
