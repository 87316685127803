import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import TableHalf from '../common/tableHalf'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import InputBase from '@material-ui/core/InputBase'
import Moment from 'moment'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'
import GetAppIcon from '@material-ui/icons/GetApp'
import { GET_STOCKS } from '../graphql/stock'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import DatepickerField from '../common/datepickerField'
import AutocompleteField from '../common/autocompleteField'
import { useSnackbar } from 'notistack'

const restApi = '/api'

const restApi2 = 'https://frapp.cloud/fishfarm'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

const tableHead = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  {
    id: 'ProductName',
    label: 'Product',
  },
  {
    id: 'Qty',
    weight: true,
    numeric: true,
    label: 'Qty',
    width: 100,
  },
]

export default function StockDisplayDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const [selected, setSelected] = useState([])
  const [closing, setClosing] = useState()
  const [confirm, setConfirm] = useState(false)
  const [open, setOpen] = useState(false)

  const { data: { stocks } = { stocks: [] }, refetch: refetchStocks } =
    useQuery(GET_STOCKS, {
      variables: {
        StockTakeID: props.data && props.data.StockTakeID,
        LocationID: props.data && props.data.LocationID,
      },
    })

  return (
    <React.Fragment>
      {/* <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Are you sure to remove this Closing?"
        okButton="Yes"
        title="Remove Closing"
      /> */}
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="sm"
          scroll="body"
          open={props.open}
          onClose={() => {
            props.setOpen(false)
            props.setSelected([])
          }}
          disableBackdropClick={false}
          classes={{ paper: classes.dialogPaper }}
          aria-labelledby="order-dialog"
        >
          <React.Fragment>
            <DialogTitle className={classes.dialogTitle} id="order-dialog">
              Stock - {props.data && props.data.LocationID}
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  props.setOpen(false)
                  props.setSelected([])
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                {stocks.length > 0 && (
                  <Grid
                    item
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    md={12}
                    xs={12}
                  >
                    {/* <h3 style={{ marginBottom: 0 }}>Sales</h3>
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        startIcon={<GetAppIcon />}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                        onClick={() => {
                          handleSaveCsv()
                        }}
                      >
                        Save CSV
                      </Button> */}
                    <TableHalf
                      hidePagination={false}
                      disableSelected={true}
                      clickOnSelect={true}
                      hideSearch={true}
                      hideDelete={true}
                      hideChange={true}
                      selected={selected}
                      setSelected={setSelected}
                      tableState={setClosing}
                      setOpen={setOpen}
                      tableData={stocks}
                      tableHead={tableHead}
                      rowsPerPage={10}
                    ></TableHalf>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
          </React.Fragment>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  )
}
