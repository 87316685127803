import React, { useState, useEffect } from 'react'
import { Link as Link2, useHistory } from 'react-router-dom'
import { navigate } from '@reach/router'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Cookies from 'js-cookie'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import axios from 'axios'
import Container from '@material-ui/core/Container'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import InputAdornment from '@material-ui/core/InputAdornment'

import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { GET_SHOPCONFIG } from './components/graphql/config'

const GET_DARKMODE = gql`
  {
    isDarkModeEnabled @client
  }
`

const restApi = '/api'

const url = '/api/users/authenticate'

const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 48px)',
    backgroundColor: 'white',
  },
  image: {
    backgroundImage: 'url(http://soonfatt.frapp.cloud/images/sfnoodles.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#49a9e3',
    '&:hover': {
      backgroundColor: '#49a9e3',
    },
  },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderColor: (props) => `${props.COLORPRIMARY}`,
    },
  },
}))

const redirectUrl = [
  { access: 'Management', url: '/sales' },
  { access: 'Marketing', url: '/sales' },
  { access: 'Sales', url: '/sales' },
  { access: 'Operation', url: '/routing' },
  { access: 'Customer Service', url: '/despatching' },
  { access: 'Accounts', url: '/despatching' },
  { access: 'Booking Agent', url: '/routing' },
  { access: 'Pickup', url: '/routing' },
]

export default function SignInSide(props) {
  const classes = useStyles()
  const history = useHistory()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.936lae.com')
      ? window.location.host.split('.936lae.com')[0]
      : 'spa2'
  )
  const {
    loading: loadingConfig,
    data: { shopconfig } = { shopconfig: {} },
    refetch: refetchConfig,
  } = useQuery(GET_SHOPCONFIG)

  const { data: { loginDetails } = { loginDetails: {} }, client } =
    useQuery(GET_DARKMODE)

  useEffect(() => {
    if (Cookies.get('signedin')) {
      props.signedIn(true)
      props.history.push('/')
    }
  }, [])

  const submitForm = (event) => {
    event.preventDefault()

    const options = {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `username=${username}&password=${password}&client=${dbhost}`,
    }

    fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            alert('Username not found, please retry')
          }
          if (response.status === 401) {
            alert('Username and password do not match, please retry')
          }
          if (response.status === 500) {
            alert('Username and password do not match, please retry')
          }
        }
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        client.writeData({ data: { loginDetails: data } })

        if (data.token) {
          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${data.token}`

          /* const expiration_date = new Date()
          expiration_date.setFullYear(expiration_date.getFullYear() + 1)

          document.cookie =
            'signedin=true; expires=' + expiration_date.toUTCString()
          document.cookie =
            `token=${data.token}; expires=` + expiration_date.toUTCString()
          document.cookie =
            `StaffID=${data.StaffID}; expires=` + expiration_date.toUTCString()
          document.cookie =
            `Department=${data.Department}; expires=` +
            expiration_date.toUTCString() */

          document.cookie = 'signedin=true'
          document.cookie = `token=${data.token}`
          document.cookie = `StaffID=${data.StaffID}`
          document.cookie = `Department=${data.Department}`

          //console.log(props)
          //navigate('/private-area')
          props.signedIn(true)
          props.history.push('/')
        }
      })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar> */}
        <img src="images/logowhite.png" style={{ height: 100 }} />
        <br />
        <Typography component="h1" variant="h5">
          {shopconfig.COMSHO}
        </Typography>

        <form onSubmit={submitForm} className={classes.form} noValidate>
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="Mobile">
              Username
            </InputLabel>
            <InputBase
              classes={{
                root: classes.rootBootstrap,
                input: classes.inputBootstrap,
              }}
              value={username}
              id="Mobile"
              name="Mobile"
              onChange={(e) => {
                setUsername(e.target.value)
              }}
              label="Username"
              fullWidth
              margin="dense"
              autoComplete="off"
              inputProps={{
                maxLength: 200,
              }}
              /* startAdornment={
                <InputAdornment position="start">
                  <WhatsAppIcon fontSize="large" style={{ color: '#4FCE5D' }} />
                </InputAdornment>
              } */
            />
            <FormHelperText></FormHelperText>
          </FormControl>

          <br />
          <br />

          <FormControl fullWidth>
            <InputLabel shrink htmlFor="Password">
              Password
            </InputLabel>
            <InputBase
              classes={{
                root: classes.rootBootstrap,
                input: classes.inputBootstrap,
              }}
              id="Password"
              name="Password"
              label="Password"
              fullWidth
              margin="dense"
              onChange={(e) => {
                setPassword(e.target.value)
              }}
              autoComplete="off"
              inputProps={{
                maxLength: 200,
                type: 'password',
              }}
            />
            <FormHelperText></FormHelperText>
          </FormControl>

          <Button
            variant="contained"
            disableElevation
            type="submit"
            fullWidth
            variant="contained"
            disableElevation
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>

          {/* <br />
          <div style={{ textAlign: 'center' }}>
            <Button
              className={classes.button}
              onClick={() => {
                scrollToTop()
                history.push('/customer')
              }}
            >
              Create account
            </Button>
          </div>
          <div style={{ marginTop: 30, textAlign: 'left' }}>
            <Button
              className={classes.button}
              onClick={() => {
                scrollToTop()
                history.push('/')
              }}
              startIcon={<ArrowBackIcon />}
            >
              Back to shopping
            </Button>
            <br />
            <br />
          </div> */}
        </form>
      </div>
    </Container>
  )
}
