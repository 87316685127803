import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useQuery, useMutation } from '@apollo/react-hooks'
import TableHalf from '../common/tableHalf'
import InputBase from '@material-ui/core/InputBase'
import InputLabel from '@material-ui/core/InputLabel'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { GET_PAYROLLS } from '../graphql/payroll'
import { GET_closingheaders } from '../graphql/officestaff'
import {
  GET_CLOSINGHEADERS,
  INSERT_CLOSINGHEADER,
  REMOVE_CLOSINGHEADER,
} from '../graphql/closing'
import { GET_GROUPBRANCHES } from '../graphql/branch'
import ConfirmationDialog from '../common/confirmationDialog'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AutocompleteField from '../common/autocompleteField'
import Loading from '../common/loading'
import ClosingDialog from './closingnewdialog'
import CostingDisplayDialog from './closingDisplayDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  displayDiv: {
    background: theme.palette.background.paper,
    padding: '20px',
    minHeight: '340px',
  },
  newButton: {
    marginBottom: 10,
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootContainer: { marginBottom: theme.spacing(2) },
  rootInputlabel: { fontWeight: 'bold' },
  rootBootstrap: {
    'label + &': {
      marginTop: theme.spacing(2),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
}))

export default function OfficeStaffPayroll() {
  const classes = useStyles()
  const [selected, setSelected] = useState([])
  const [open, setOpen] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openHeader, setOpenHeader] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [closing, setClosing] = useState()
  const [openClosing, setOpenClosing] = useState(false)
  const [openClosingDetail, setOpenClosingDetail] = useState(false)
  const [branch, setBranch] = useState()

  const {
    loading: loadingBranch,
    data: { groupbranches } = { groupbranches: [] },
    refetch: refetchBranch,
  } = useQuery(GET_GROUPBRANCHES)

  const {
    loading: loadingClosing,
    data: { closingheaders } = { closingheaders: [] },
    refetch: refetchClosingHeaders,
  } = useQuery(GET_CLOSINGHEADERS)

  const [staff, setStaff] = useState('')
  const [staffName, setStaffName] = useState('')
  const [quotation, setQuotation] = useState()

  const handleClickOpen = () => {
    setOpenClosing(true)
  }

  const tableHead2 = [
    { label: '', col: 1 },
    { label: '', col: 2 },
    { label: 'Opening Balance', col: 1 },
    { label: 'Stock In', col: 1 },
    { label: 'Stock Out', col: 1 },
    { label: 'Closing Balance', col: 1 },
  ]
  const tableHead = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'ClosingDateStart',
      date: true,
      label: 'Closing Start',
      width: 150,
    },
    {
      id: 'ClosingDate',
      date: true,
      label: 'Closing End',
      row: 2,
      width: 150,
    },
    {
      id: 'OpeningBalanceQty',
      weight: true,
      numeric: true,
      label: 'Qty',
      width: 100,
    },
    {
      id: 'StockInQty',
      weight: true,
      numeric: true,
      label: 'Qty',
      width: 100,
    },
    {
      id: 'StockOutQty',
      weight: true,
      numeric: true,
      label: 'Qty',
      width: 100,
    },
    {
      id: 'ClosingBalanceQty',
      weight: true,
      numeric: true,
      label: 'Qty',
      width: 100,
    },
  ]

  /* const tableButtons = [
    {
      name: 'Active',
      icon: EmojiObjectsIcon,
      method: handleClickActive,
    },
    {
      name: 'Set Prices & Print Quotation',
      icon: AttachMoneyIcon,
      method: handlePrint,
    },
  ] */

  /* if (error) return <p>API ERROR</p> */

  return (
    <div className={classes.root}>
      {/*  <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Quote?"
        okButton="Yes"
        title="Continue remove"
      /> */}
      <ClosingDialog
        /* data={payroll} */
        setClosing={setClosing}
        setSelected={setSelected}
        open={openClosing}
        setOpen={setOpenClosing}
        branch={branch}
      />
      <CostingDisplayDialog
        data={closing}
        setClosing={setClosing}
        setSelected={setSelected}
        open={openClosingDetail}
        branch={branch}
        setOpen={setOpenClosingDetail}
      />
      {/* 
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          {loadingStaff && <Loading />}
          {!loadingStaff && (
            <AutocompleteField
              name="buyer"
              label="Staff"
              value={staffName}
              options={closingheaders.map(
                (item) =>
                  `${item.Username} — ${item.FirstName} ${item.LastName}`
              )}
              onChange={(e, value) => {
                const buyerValue = closingheaders.find(
                  (item) =>
                    `${item.Username} — ${item.FirstName} ${item.LastName}` ===
                    value
                )
                if (buyerValue) {
                  refetch({ UserID: buyerValue.UserID })
                  setStaff(buyerValue.UserID)
                  setStaffName(
                    `${buyerValue.Username} — ${buyerValue.FirstName} ${buyerValue.LastName}`
                  )
                }
              }}
            />
          )}
        </Grid>
      </Grid> */}
      {!loadingBranch && (
        <Grid container classes={{ root: classes.rootContainer }}>
          <Grid item xs={6} md={2}>
            <FormControl /* error={errors.Feed && true} */ fullWidth>
              <InputLabel
                classes={{ root: classes.rootInputlabel }}
                shrink
                htmlFor="Branch"
              >
                Select Branch
              </InputLabel>
              <Select
                labelId="Branch"
                id="Branch"
                name="Branch"
                value={branch}
                onChange={(e) => {
                  setBranch(e.target.value)
                  refetchClosingHeaders({
                    BranchCode: e.target.value,
                    ClosingType: 'STOCK',
                  })
                }}
                input={
                  <InputBase
                    classes={{
                      root: classes.rootBootstrap,
                      input: classes.inputBootstrap,
                    }}
                  />
                }
                /*  inputRef={register({
              required: 'This field is Required'
            })} */
              >
                {groupbranches.map((a, i) => (
                  <MenuItem key={i} value={a.BranchCode}>
                    {a.BranchCode} - {a.BranchName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {/* {errors.Feed && errors.Feed.message} */}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {branch && (
        <Grid container spacing={0}>
          <Button
            variant="contained"
            classes={{ root: classes.newButton }}
            color="primary"
            onClick={handleClickOpen}
          >
            New
          </Button>
          <Button
            variant="outlined"
            classes={{ root: classes.newButton }}
            style={{ marginLeft: 5 }}
            color="secondary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              refetchClosingHeaders({
                BranchCode: branch,
                ClosingType: 'STOCK',
              })
            }}
          >
            Refresh
          </Button>
          <Grid item xs={12} sm={12}>
            {/* <Report key={+new Date() + Math.random()} customer={customer} /> */}

            {/* <QuotationHeaderDialog
            key={+new Date() + Math.random()}
            setOpenHeader={setOpenHeader}
            setSelected={setSelected}
            setQuotation={setQuotation}
            data={quotation}
            customer={customer}
            openHeader={openHeader}
          />

          <QuotationDialog
            key={+new Date() + Math.random()}
            setOpen={setOpen}
            setSelected={setSelected}
            setQuotation={setQuotation}
            data={quotation}
            open={open}
          /> */}
          </Grid>
          <Grid style={{ marginTop: 30 }} item xs={12} sm={12}>
            {/*  <pre>{JSON.stringify(payroll)}</pre> */}
            <TableHalf
              clickOnSelect={true}
              hideSearch={true}
              hideDelete={true}
              hideChange={true}
              selected={selected}
              setSelected={setSelected}
              tableState={setClosing}
              tableData={closingheaders}
              setOpen={setOpenClosingDetail}
              tableHead={tableHead}
              tableHead2={tableHead2}
            ></TableHalf>

            {/*  {!loading && customer && quotations.length === 0 && (
            <p>No Quotation found.</p>
          )}
          {!loading && customer && quotations.length > 0 && (
            <TableHalf
              tableButtons={tableButtons}
              setConfirm={setConfirm}
              selected={selected}
              setSelected={setSelected}
              tableState={setQuotation}
              tableData={payrollheaders}
              setOpen={setOpenHeader}
              tableHead={tableHead}
            ></TableHalf>
          )} */}
          </Grid>
        </Grid>
      )}
    </div>
  )
}
