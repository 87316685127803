import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link as Link2, useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import useForm from 'react-hook-form'
import { useSnackbar } from 'notistack'
import TextField from '@material-ui/core/TextField'
import TextFieldDisplay from '../common/textFieldDisplay'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_ORDERS, UPDATE_ZONE } from '../graphql/order'
import Cookies from 'js-cookie'
import QrReader from 'react-qr-reader'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
}))

// START Cancelled
export default function OrderScanDialog(props) {
  const classes = useStyles()
  const history = useHistory()
  const myRef = React.useRef()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { handleSubmit, register, errors } = useForm()
  const [barcode, setBarcode] = useState()
  /* 
  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_ORDERS,
    })
    const latest = data.updateZone
    const foundIndex = old.orders.findIndex((item) => item.id === latest.id)
    old.orders.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_ORDERS,
      data: { orders: [...old.orders] },
    })
  }

  const [updateZone] = useMutation(UPDATE_ZONE, { update: updateCache })
 */
  const onSubmit = (values) => {
    console.log(values)

    /* updateZone({
      variables: {
        id: props.data.id,
        ZoneID: props.data.ZoneID,
        Status: 'Cancelled',
        CreatedBy: Cookies.get('StaffID'),
        StatusText: values.Reason,
      },
    }) */
    //props.setOpen(false)

    handleScan(barcode)
  }

  const [scanresult, setScanresult] = useState()

  const handleScan = (data) => {
    if (data && typeof data == 'string') {
      setScanresult(data)
      console.log('data', data)
      const scansplit = data.split('/')

      history.push(`/orderdetails/${scansplit[scansplit.length - 1]}`)
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const openImageDialog = () => {
    myRef.current.openImageDialog()
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="billingdetails-dialog"
      >
        <React.Fragment>
          <DialogTitle
            className={classes.dialogTitle}
            id="billingdetails-dialog"
          >
            Scan QR Code
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={0}>
                <Grid style={{ padding: 0 }} item md={12} xs={12}>
                  <QrReader
                    ref={myRef}
                    /* legacyMode */
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    /*  onImageLoad={handleScan} */
                    style={{ width: '100%' }}
                  />
                  <br />
                  <br />
                  <TextField
                    id="outlined-basic"
                    label="Scanner"
                    variant="outlined"
                    value={barcode}
                    autoFocus={true}
                    onChange={(event) => {
                      setBarcode(event.target.value)
                    }}
                  />
                  {/* <input
                    type="button"
                    value="Submit QR Code"
                    onClick={openImageDialog}
                  /> */}

                  <br />
                  <br />
                  <br />
                </Grid>
              </Grid>
            </form>
            {/* <pre>{JSON.stringify(scanresult, null, 4)}</pre> */}
          </DialogContent>
          {/* <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </DialogActions> */}
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
