import { gql } from 'apollo-boost'

export const GET_BOOKINGS = gql`
  {
    bookings {
      id
      BookingNo
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
      FullName
      TotalPerson
      Error
    }
  }
`
export const BOOKING_SCHEDULE = gql`
  query BookingSchedules($BookingDate: Date) {
    bookingschedules(BookingDate: $BookingDate) {
      id
      title
      value
    }
  }
`

export const GET_BOOKINGORDERS = gql`
  query BookingOrders($id: ID) {
    bookingorders(id: $id) {
      id
      title
      value
      MinBooking
    }
  }
`

export const GET_BOOKINGDETAILS = gql`
  query BookingDetails($Token: ID) {
    bookingdetails(Token: $Token) {
      id
      BookingNo
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
      FullName
      TotalPerson
      Error
    }
  }
`

export const RECEIVED_BOOKING = gql`
  mutation ReceivedBooking(
    $Token: ID
    $SalesPerson: String
    $Password: String
  ) {
    receivedbooking(
      Token: $Token
      SalesPerson: $SalesPerson
      Password: $Password
    ) {
      id
      BookingNo
      BranchID
      BranchName
      BranchCode
      BookFrom
      BookTo
      TotalHours
      Status
      CreatedBy
      CreatedAt
      UpdatedBy
      UpdatedAt
      Token
      ReceivedBy
      ReceivedOn
      Client
      FirstName
      LastName
      ServiceTimes
      FullName
      TotalPerson
      Error
    }
  }
`

export const CANCEL_BOOKING = gql`
  mutation CancelBooking($id: ID, $SalesPerson: String, $Password: String) {
    cancelbooking(id: $id, SalesPerson: $SalesPerson, Password: $Password) {
      id
      Error
    }
  }
`

export const INSERT_BOOKING = gql`
  mutation InsertBooking(
    $CustomerID: String
    $BookDate: Date
    $BookTime: String
    $TotalHours: Float
    $TotalPerson: Int
    $SalesPerson: String
    $Password: String
  ) {
    insertbooking(
      CustomerID: $CustomerID
      BookDate: $BookDate
      BookTime: $BookTime
      TotalHours: $TotalHours
      TotalPerson: $TotalPerson
      SalesPerson: $SalesPerson
      Password: $Password
    ) {
      id
      Error
    }
  }
`

export const REMOVE_BOOKING = gql`
  mutation RemoveBooking($id: ID, $SalesPerson: String, $Password: String) {
    removebooking(id: $id, SalesPerson: $SalesPerson, Password: $Password) {
      id
      Error
    }
  }
`
