import { gql } from 'apollo-boost'

export const LEAVE_HISTORY = gql`
  {
    leavehistory {
      id
      UserID
      LeaveType
      FromDate
      ToDate
      NoOfDays
      Approved
      LeaveDesc
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
    }
  }
`

export const UPDATE_LEAVE = gql`
  mutation UpdateLeave(
    $id: ID
    $LeaveType: String
    $FromDate: Date
    $ToDate: Date
    $NoOfDays: Float
  ) {
    updateleave(
      id: $id
      LeaveType: $LeaveType
      FromDate: $FromDate
      ToDate: $ToDate
      NoOfDays: $NoOfDays
    ) {
      id
      UserID
      LeaveType
      FromDate
      ToDate
      NoOfDays
      Approved
      LeaveDesc
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
    }
  }
`
export const INSERT_LEAVE = gql`
  mutation InsertLeave(
    $LeaveType: String
    $FromDate: Date
    $ToDate: Date
    $NoOfDays: Float
  ) {
    insertleave(
      LeaveType: $LeaveType
      FromDate: $FromDate
      ToDate: $ToDate
      NoOfDays: $NoOfDays
    ) {
      id
      UserID
      LeaveType
      FromDate
      ToDate
      NoOfDays
      Approved
      LeaveDesc
      CreatedBy
      CreatedOn
      UpdatedBy
      UpdatedOn
    }
  }
`

export const REMOVE_LEAVE = gql`
  mutation RemoveLeave($id: ID!) {
    removeleave(id: $id)
  }
`
