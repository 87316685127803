import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { useMutation, useQuery } from '@apollo/react-hooks'
import Divider from '@material-ui/core/Divider'
import CurrencyFormat from 'react-currency-format'
import { GET_CATEGORIES } from '../graphql/category'
import {
  INSERT_PRODUCT,
  GET_PRODUCTS,
  UPDATE_PRODUCT,
  PRODUCT_IMAGES,
  MAKE_IMAGEPRIMARY,
  GET_PRICELIST,
  REMOVE_PRODUCT,
} from '../graphql/product'

import TextField from '@material-ui/core/TextField'
import ConfirmationDialog from '../common/confirmationDialog'
import useForm from 'react-hook-form'
import Loading from '../common/loading'
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete'
import ProductPriceDialog from './productPriceDialog'
import ProductCancelDialog from './productCancelDialog'
import ProductUploadDialog from './productUploadDialog'
import Cookies from 'js-cookie'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import { Editor } from '@tinymce/tinymce-react'
import ProductResizeDialog from './productResizeDialog'

import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
}))

export default function ProductDialog(props) {
  const classes = useStyles()
  const { handleSubmit, register, errors, setValue } = useForm()
  const [confirm, setConfirm] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const [openUpload, setOpenUpload] = useState(false)
  const [openResize, setOpenResize] = useState(false)
  const [openPrice, setOpenPrice] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [resizeFileName, setResizeFileName] = useState()
  const [confirmDeleteUpload, setConfirmDeleteUpload] = useState(false)
  const [showImage, setShowImage] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [selectedPrice, setSelectedPrice] = useState()
  const [productDesc, setProductDesc] = useState()

  const [category, setCategory] = useState()

  /* const [dbhost, setDbhost] = useState(
    window.location.host.endsWith('.frapp.cloud')
      ? window.location.host.split('.frapp.cloud')[0]
      : ''
  )*/

  const [dbhost, setDbhost] = useState('spa1')

  const addCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    const latest = data.insertproduct
    cache.writeQuery({
      query: GET_PRODUCTS,
      data: { products: [latest, ...old.products] },
    })

    props.setSelected([latest.id])
    props.setProduct(latest)
  }

  const updateCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    const latest = data.updateproduct
    const foundIndex = old.products.findIndex((item) => item.id === latest.id)
    old.products.splice(foundIndex, 1, latest)
    cache.writeQuery({
      query: GET_PRODUCTS,
      data: { products: [...old.products] },
    })

    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const deleteCache = (cache, { data }) => {
    const old = cache.readQuery({
      query: GET_PRODUCTS,
    })
    if (data.removeproduct) {
      const latest = old.products.filter((item) => item.id !== props.data.id)
      cache.writeQuery({
        query: GET_PRODUCTS,
        data: { products: [...latest] },
      })
      props.setSelected([])
      props.setProduct()
    }
  }

  const [insertProduct] = useMutation(INSERT_PRODUCT, { update: addCache })
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    update: updateCache,
  })
  const [removeProduct] = useMutation(REMOVE_PRODUCT, {
    update: deleteCache,
  })
  const [makePrimaryImage] = useMutation(MAKE_IMAGEPRIMARY)

  const {
    loading: loadingProductImages,
    data: { productimages } = { productimages: [] },
    refetch: refetchProductImages,
  } = useQuery(PRODUCT_IMAGES, {
    variables: { id: props.data && props.data.id },
    skip: !props.data,
  })

  const {
    loading: loadingPriceList,
    data: { pricelist } = { pricelist: [] },
    refetch: refetchPriceList,
  } = useQuery(GET_PRICELIST, {
    variables: { ProductID: props.data && props.data.id },
    skip: !props.data,
  })

  const { data: { categories } = { categories: [] } } = useQuery(GET_CATEGORIES)

  useEffect(() => {
    if (!props.data) return

    setCategory(props.data.Category)
  }, [props])

  const onSubmit = (values) => {
    if (props.data && props.data.id) {
      updateProduct({
        variables: {
          id: props.data.id,
          Category: category,
          Ordering: values.Ordering,
          IDSKU: values.IDSKU,
          ProductName: values.ProductName,
          Quantity: values.Quantity,
          UnitPrice: parseFloat(values.UnitPrice),
          ProductDesc: productDesc,
        },
      })
    } else {
      insertProduct({
        variables: {
          Category: category,
          Ordering: values.Ordering,
          IDSKU: values.IDSKU,
          ProductName: values.ProductName,
          Quantity: values.Quantity,
          UnitPrice: parseFloat(values.UnitPrice),
          ProductDesc: productDesc,
        },
      })
    }
  }

  const handleClickDelete = () => {
    removeProduct({
      variables: {
        id: props.data.id,
      },
    })
    setConfirm(false)
    props.setOpen(false)
    props.setSelected([])
    props.setProduct()
  }

  const handleClickDeleteImage = () => {
    axios
      .get(`${restApi}/upload/remove/${selectedImage}`)
      .then((response) => {
        refetchProductImages()
      })
      .catch((error) => console.log(error))
    setConfirmDeleteUpload(false)
  }

  const handleEditorChange = (e) => {
    setProductDesc(e.target.getContent())
    //console.log('Content was updated:', e.target.getContent())
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        action={handleClickDelete}
        confirm={confirm}
        setConfirm={setConfirm}
        message="Continue remove Product?"
        okButton="Yes"
        title="Continue remove"
      />
      <ConfirmationDialog
        action={handleClickDeleteImage}
        confirm={confirmDeleteUpload}
        setConfirm={setConfirmDeleteUpload}
        message="Continue remove Product Images?"
        okButton="Yes"
        title="Continue remove"
      />
      <ProductUploadDialog
        data={props.data}
        open={openUpload}
        setOpen={setOpenUpload}
        setResizeFileName={setResizeFileName}
        setOpenResize={setOpenResize}
        refetch={refetchProductImages}
      />
      <ProductPriceDialog
        key={+new Date() + Math.random()}
        open={openPrice}
        setOpen={setOpenPrice}
        product={props.data}
        data={selectedPrice}
        setSelected={setSelectedPrice}
      />
      <Dialog
        fullWidth={true}
        maxWidth="md"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
          props.setSelected([])
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="order-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="order-dialog">
            Product Details
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
                props.setSelected([])
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent className={classes.dialogContent}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  {/* <TextField
                    name="Category"
                    label="Category"
                    margin="dense"
                    fullWidth
                    defaultValue={props.data && props.data.Category}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.Category}
                    helperText={errors.Category && errors.Category.message}
                  /> */}
                  {/*  <TextField
                    name="IDSKU"
                    label="SKU"
                    margin="dense"
                    defaultValue={props.data && props.data.IDSKU}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.IDSKU}
                    helperText={errors.IDSKU && errors.IDSKU.message}
                  /> */}
                  <TextField
                    name="ProductName"
                    label="Product Name"
                    margin="dense"
                    fullWidth
                    multiline
                    defaultValue={props.data && props.data.ProductName}
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.ProductName}
                    helperText={
                      errors.ProductName && errors.ProductName.message
                    }
                  />
                </Grid>
              </Grid>

              {props.data && props.data.id && (
                <React.Fragment>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={category}
                      defaultValue={category}
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                    >
                      {categories.map((a, i) => (
                        <MenuItem key={i} value={a.id}>
                          {a.CategoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />

                  <TextField
                    name="UnitsInStock"
                    label="In Stock"
                    margin="dense"
                    type="number"
                    fullWidth
                    defaultValue={props.data && props.data.UnitsInStock}
                    style={{ width: 120 }}
                    autoComplete="off"
                    inputProps={{ min: '0', step: 'any' }}
                    inputRef={register({ required: 'Required' })}
                    error={errors.UnitsInStock}
                    helperText={
                      errors.UnitsInStock && errors.UnitsInStock.message
                    }
                  />
                  <br />
                  <TextField
                    name="Ordering"
                    label="Ordering"
                    margin="dense"
                    type="number"
                    fullWidth
                    defaultValue={props.data && props.data.Ordering}
                    style={{ width: 120 }}
                    autoComplete="off"
                    inputProps={{ min: '0', step: 'any' }}
                    inputRef={register({ required: 'Required' })}
                    error={errors.Ordering}
                    helperText={errors.Ordering && errors.Ordering.message}
                  />

                  <Divider classes={{ root: classes.dividerroot }} />

                  <React.Fragment>
                    <h5 className={classes.h5}>Price</h5>

                    <Grid container spacing={3}>
                      {pricelist.map((price, i) => (
                        <Grid item md={4} xs={12}>
                          <List className={classes.root}>
                            <ListItem
                              key={i}
                              dense
                              button
                              onClick={() => {
                                setSelectedPrice(price)
                                setOpenPrice(true)
                              }}
                            >
                              <ListItemAvatar>
                                <Avatar>{i + 1}</Avatar>
                              </ListItemAvatar>
                              {
                                <ListItemText
                                  primary={`${price.Uom}`}
                                  secondary={
                                    <CurrencyFormat
                                      value={price.UnitPrice}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'RM'}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                  }
                                />
                              }
                              {/* <ListItemText primary={`RM ${price.UnitPrice}`} /> */}
                            </ListItem>
                          </List>
                        </Grid>
                      ))}
                    </Grid>
                    {props.data && pricelist.length === 0 && (
                      <React.Fragment>
                        <br />
                        <br />
                        No Price found.
                      </React.Fragment>
                    )}
                    {/*  {props.data && pricelist.length === 0 && ( */}
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        margin="dense"
                        onClick={() => {
                          setOpenPrice(true)
                        }}
                        variant="contained"
                        disableElevation
                        color="primary"
                      >
                        Add Price
                      </Button>
                    </div>
                    {/* )} */}
                  </React.Fragment>
                  {/* <TextField
                    name="UnitPrice"
                    label="Unit Price"
                    margin="dense"
                    type="number"
                    fullWidth
                    defaultValue={props.data && props.data.UnitPrice}
                    style={{ width: 200 }}
                    autoComplete="off"
                    inputProps={{ min: '0', step: 'any' }}
                    inputRef={register({ required: 'Required' })}
                    error={errors.UnitPrice}
                    helperText={errors.UnitPrice && errors.UnitPrice.message}
                  /> */}

                  {/* <TextField
                    name="ProductDesc"
                    label="ProductDesc"
                    margin="dense"
                    defaultValue={props.data && props.data.ProductDesc}
                    fullWidth
                    multiline
                    autoComplete="off"
                    inputRef={register({ required: 'Required' })}
                    error={errors.ProductDesc}
                    helperText={
                      errors.ProductDesc && errors.ProductDesc.message
                    }
                  /> */}
                  <Divider classes={{ root: classes.dividerroot }} />

                  <React.Fragment>
                    <h5 className={classes.h5}>Product Images</h5>
                    <Grid container spacing={3}>
                      {props.data &&
                        props.data.id &&
                        productimages.map((image, i) => (
                          <Grid item md={4} xs={12}>
                            <List className={classes.root}>
                              <ListItem key={i}>
                                <img
                                  src={`${restApi}/media/${dbhost}/uploads/invoice/${image.FileName}`}
                                  className={classes.listimage}
                                  onClick={() => {
                                    setShowImage(
                                      `${restApi}/media/${dbhost}/uploads/invoice/${image.FileName}`
                                    )
                                    setOpenImage(!openImage)
                                  }}
                                />
                                <ListItemText
                                  primary={
                                    image.Ordering == 0 ? 'Cover Photo' : ''
                                  }
                                />
                                {image.Ordering != 0 && (
                                  <Button
                                    margin="dense"
                                    onClick={() => {
                                      makePrimaryImage({
                                        variables: {
                                          id: image.id,
                                          ModuleID: props.data && props.data.id,
                                        },
                                      })
                                      setTimeout(() => {
                                        refetchProductImages()
                                      }, 2000)
                                    }}
                                    color="primary"
                                  >
                                    Make Cover Photo
                                  </Button>
                                )}
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedImage(image.id)
                                      setConfirmDeleteUpload(true)
                                    }}
                                    edge="end"
                                    aria-label="comments"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </List>
                          </Grid>
                        ))}
                    </Grid>
                    {openImage && (
                      <dialog
                        className={classes.imagedialog}
                        style={{ position: 'absolute' }}
                        open
                      >
                        <img
                          className={classes.big}
                          src={showImage}
                          onClick={() => {
                            setOpenImage(false)
                          }}
                        />
                      </dialog>
                    )}
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        margin="dense"
                        onClick={() => {
                          setOpenUpload(true)

                          //setOpenResize(true)
                        }}
                        variant="contained"
                        disableElevation
                        color="primary"
                      >
                        Add image
                      </Button>
                    </div>
                  </React.Fragment>

                  <Divider classes={{ root: classes.dividerroot }} />

                  <h5 className={classes.h5}>Product Details</h5>
                  <Editor
                    apiKey="ddwtpmbv25bslslv86dfnxcn8n9tzcdg44odrmgp2h9phjty"
                    initialValue={props.data && props.data.ProductDesc}
                    init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image',
                        'charmap print preview anchor help',
                        'searchreplace visualblocks code',
                        'insertdatetime media table paste wordcount',
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic | \
                          alignleft aligncenter alignright | \
                          bullist numlist outdent indent',
                    }}
                    onChange={handleEditorChange}
                  />
                </React.Fragment>
              )}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {props.data && (
                <React.Fragment>
                  <IconButton
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      setConfirm(true)
                    }}
                    edge="end"
                    aria-label="comments"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    margin="dense"
                    variant="contained"
                    disableElevation
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </React.Fragment>
              )}
              {!props.data && (
                <Button
                  margin="dense"
                  variant="contained"
                  disableElevation
                  color="primary"
                  type="submit"
                >
                  Next
                </Button>
              )}
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
