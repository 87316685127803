import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import axios from 'axios'
import { DropzoneArea } from 'material-ui-dropzone'
import { useSnackbar } from 'notistack'

// const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT
const restApi = '/api'

const useStyles = makeStyles((theme) => ({
  dropZone: {
    background: theme.palette.primary.paper,
  },
  rootList: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listimage: {
    width: 100,
    paddingRight: 10,
  },
  h5: {
    marginBottom: 5,
    marginTop: 5,
  },
  big: {
    width: 800,
  },
  imagedialog: {
    boxShadow: '0 8px 6px -6px black',
    position: 'static',
    left: '20%',
    top: '10%',
    zIndex: 9999,
  },
  dialogPaper: {
    background: theme.palette.primary.paper,
    overflowY: 'visible',
  },
  dialogTitle: {
    background: theme.palette.primary.backgroundColor,
    color: theme.palette.primary.main,
  },
  dialogContent: {
    background: theme.palette.primary.backgroundColor,
    overflowY: 'visible',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.primary.main,
  },
  dialogActions: {
    padding: theme.spacing(3),
  },
  backDrop: { backgroundColor: 'transparent' },
  dividerroot: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginTop: 5,
    minWidth: 250,
  },
  rootBootstrap: {
    marginTop: 8,
    fontWeight: 'bold!important',
    'label + &': {
      fontWeight: 'bold!important',
      marginTop: theme.spacing(3),
    },
  },
  inputBootstrap: {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '2px solid #ced4da',
    width: '100%',
    padding: '10px',
    paddingBottom: '10px!important',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    '&:focus': {
      borderRadius: 6,
      borderColor: theme.palette.primary.main,
    },
  },
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
}))

// START
export default function HealthreportUploadDialog(props) {
  const classes = useStyles()
  const [file, setFile] = useState()
  const [disable, setDisable] = useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (file) setDisable(false)
  }, [file])

  const handleUpload = (e) => {
    e.preventDefault()

    if (!file) return
    const formData = new FormData()
    //formData.append('uploads[]', file)
    console.log(file)
    file.file.map((a) => {
      formData.append('uploads[]', a, a['name'])
    })
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    //formData.append("uploads[]", files[0], files[0]['name']);
    axios
      .put(restApi + `/upload/healthreport/${props.customer}`, formData, config)
      .then((response) => {
        enqueueSnackbar('The file is successfully uploaded', {
          variant: 'success',
        })
        props.setOpen(false)
        props.refetch()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const onChange = (e) => {
    console.log(e)
    setFile({ file: e })
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        scroll="body"
        open={props.open}
        onClose={() => {
          props.setOpen(false)
        }}
        disableBackdropClick={false}
        classes={{ paper: classes.dialogPaper }}
        aria-labelledby="manifesting-dialog"
      >
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle} id="manifesting-dialog">
            Attachment
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {
                props.setOpen(false)
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <form onSubmit={handleUpload}>
            <DialogContent className={classes.dialogContent}>
              <DropzoneArea
                showPreviews={true}
                showPreviewsInDropzone={false}
                useChipsForPreview
                previewGridProps={{
                  container: { spacing: 1, direction: 'row' },
                }}
                previewChipProps={{ classes: { root: classes.previewChip } }}
                dropzoneClass={classes.dropZone}
                maxFileSize={40000000}
                filesLimit={100}
                acceptedFiles={['.html', '.htm']}
                dropzoneText="Select single or multiple Health Report HTML file"
                onChange={onChange}
              />
              {/* <input type="file" name="myImage" onChange={onChange} /> */}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                type="submit"
                disabled={disable}
              >
                Upload
              </Button>
            </DialogActions>
          </form>
        </React.Fragment>
      </Dialog>
    </React.Fragment>
  )
}
